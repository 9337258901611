import { CHATID_SET, CHATS_SET } from "state/actions/chats";
import { createReducer } from 'redux-act';

const initialState = {
    data: [],
    chatId: null

};

export const chatsReducer = createReducer(
    {
    [CHATS_SET]: (state, payload) => ({
        ...state,
        data: payload.data,
        }),
    [CHATID_SET]: (state, payload) => ({
        ...state,
        chatId: payload.data
        }),
    },
    
    initialState
  );