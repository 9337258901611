import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import classNames from 'classnames';
import ClipLoader from 'react-spinners/ClipLoader';

import { useFormatMessage, } from 'hooks';
import Table from 'components/Table';
import { deleteShipment, fetchShipmentsForQuoting, shipmentsCleanUp } from 'state/actions/shipments';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';
import classes from './Shipments.module.scss';


const Quotes = () => {
  
  const { shipmentsList, isAdmin, error, loading, deleted } = useSelector(
    (state) => ({
      shipmentsList: state.shipments.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.shipments.error,
      loading: state.auth.loading,
      deleted: state.auth.deleted,
    }),
    shallowEqual
  );

  const [deleteModal, setDeleteModal] = useState({
    shipmentId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  useEffect(() => {
    if (isAdmin) {
      console.log('fetching'); 
      dispatch(fetchShipmentsForQuoting());
    }

    return () => dispatch(shipmentsCleanUp());
  }, [dispatch, isAdmin]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        shipmentId: null,
        isOpen: !prevState.isOpen,
      }));
    }
  }, [deleted, loading]);

  const redirect = !isAdmin && <Redirect to={paths.ROOT} />;

  // const onRemoveButtonClickHandler = (shipmentId) => {
  //   setDeleteModal((prevState) => ({
  //     shipmentId,
  //     isOpen: !prevState.isOpen,
  //   }));
  // };

  const onCloseModalHandler = () => {
    setDeleteModal({ shipmentId: null, isOpen: false });
  };

  const onDeleteUserHandler = () => {
    dispatch(deleteShipment(deleteModal.shipmentId));
  };
  console.log(shipmentsList);
  const columns = [
    {
      Header: useFormatMessage('Shipments.#'),
      accessor: 'uid',
    },
    {
      Header: useFormatMessage('Shipments.from'),
      accessor: 'shipmentDetails.collectionDetails.address.postcode',
    },
    {
      Header: useFormatMessage('Shipments.to'),
      accessor: 'shipmentDetails.deliveryDetails.address.postcode',
    },
    {
      Header: useFormatMessage('Shipments.date & time'),
      accessor: 'shipmentDetails.dateAndTime.date',
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {row.original.shipmentDetails.dateAndTime.date}
        </small>
      ),
    },
    {
      Header: useFormatMessage('Shipments.status'),
      accessor: 'status',
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <>
          {!row.original.isAdmin && (
            <div className="buttons is-right">
              <Link
                to={`/quotes/${row.original.id}`}
                className="button is-small is-primary"
              >
                <span className="icon is-small">
                  <i className="mdi mdi-note-plus" />
                </span>
              </Link>
            </div>
          )}
        </>
      ),
      disableSortBy: true,
    },
  ];

  const data = search
    ? shipmentsList.filter((el) => {
        const clonedElem = { ...el };
        delete clonedElem.id;
        delete clonedElem.proofOfDeliveryUrl;
        delete clonedElem.cargoDetails;
        delete clonedElem.invoiceable;
        delete clonedElem.creation;
        delete clonedElem.proofOfDeliveryUrl;
        delete clonedElem.routeTime;
        delete clonedElem.quoteInformation;
        delete clonedElem.priceInformation;
        delete clonedElem.route;
        delete clonedElem.paymentInformation;
        delete clonedElem.shipmentDetails;
        delete clonedElem.creator;
        return Object.values(clonedElem).some((field) =>
          field.toLowerCase().includes(search.toLowerCase())
        );
      })
    : shipmentsList;

  const deleteMessage = useFormatMessage('Shipments.delete');

  const confirmMessage = useFormatMessage('Shipments.confirm');

  const permDeleteMessage = useFormatMessage('Shipments.permDelete');

  const cancelMessage = useFormatMessage('Shipments.cancel');

  return (
    <>
      {redirect}
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteUserHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">{useFormatMessage('Quotes.quotes')}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className={classNames('card-header-title', classes.tableHeader)}>
              <span>{useFormatMessage('Shipments.search')}</span>
              <input
                type="text"
                className="input"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </p>
          </header>
          <div className="b-table">
            {loading ? <ClipLoader /> : <Table columns={columns} data={data} />}
            {error && 'Show error'}
          </div>
        </div>
      </section>
    </>
  );
};

export default Quotes;
