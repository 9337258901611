import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
/* eslint-disable */
import ChatSingleItem from './item';
import { closeChat, sendMessage } from 'services/chats';
import { useMessages } from 'hooks/useMessages';
import './styles.scss';
import { useSelector } from 'react-redux';

const ChatSingleScreen = ({chatId, data}) => {

    console.log('Data',data)
    if(data == null){
        
        return(
            <p>This chat has been closed</p>
        );
    }
    const client = useSelector(state => state.users.data.filter((item) => item.id === data?.creator)[0]);
    const currentUserShipments = useSelector(state => state.shipments.data);
    const { messages } = useMessages(chatId);
    // const firstMessage = messages[messages.length-1] //to be unfreezed with time module
    const pusher = useRef()
    const [message, setMessage] = useState('');
    // const [scrollOffset, setScrollOffset] = useState()
    const handleMessageSend = (e) => {
        e.preventDefault();
        if (message.length == 0) {
            return
        }
        setMessage('');
        sendMessage(chatId, message );
        pusher.current.scrollIntoView();
    };

    
    //---------------------------------------------------------------------
    //Handles the scroll height to maintain when rerendering

    // const usePrevious = (value) => {
    //     const ref = useRef();
    //     useEffect(() => {
    //         ref.current = value;
    //     });
    //     return ref.current;
    // };
    
    // // Save scroll position when Component unmounts
    // useLayoutEffect(() => {
    //     return () => {
    //         setScrollOffset(parent.messageList, scrollPos.current);
    //     };
    // }, [parent.messageList]);
    
    // // Save scroll position when Parent ID changes
    // const oldParent = usePrevious(parent);
    // if (oldParent && parent.messageList !== oldParent.messageList) {
    //     setScrollOffset(oldParent.messageList, list ? list.scrollTop : 0);
    // }



    //------------------------------------------------
    function RenderMessages(items) {
        console.log("items", items);
        return (
            <div className='chatWrapper'>
                {items.data?.map((item) => <ChatSingleItem item={item} />)}
                <span ref={pusher}></span>
            </div> 
        );
    };

    const shipmentData = data?.shipmentId === false ?
        false
        :
        currentUserShipments.filter((shipment) => shipment.id === data.shipmentId)[0];  

    const handleCloseCase = () =>{
        closeChat(chatId)
    }
    //-------------------------------------------------------------------
    //Handles the time indicator (to be implemented when scrollY maintain is fixed)
    // const calculateTimeSince = () => {
    //     const now = new Date()
    //     const collection = firstMessage?.creation.seconds? new Date(firstMessage?.creation.seconds * 1000): new Date()
    //     const difference = +now - +collection;
    //     let timeSince = {};
    
    //     if (difference > 0) {
    //         timeSince = {
    //         days: Math.floor(difference / (1000 * 60 * 60 * 24)).toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false}),
    //         hours: Math.floor((difference / (1000 * 60 * 60)) % 24).toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false}),
    //         minutes: Math.floor((difference / 1000 / 60) % 60).toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false}),
    //         seconds: Math.floor((difference / 1000) % 60).toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false}),
    //         };
    //     }
    
    //     return timeSince;
    //     };
    
    //     const [timeSince, setTimeSince] = useState(calculateTimeSince());
    
    //     useEffect(() => {
    //     setTimeout(() => {
    //         setTimeSince(calculateTimeSince());
    //     }, 1000);
    //     });

    return (  
        <div style={{width: '100%'}}>
            <div className='chatHeader'>
                <div className='clientWrapper'>
                    <div className='profileImage'/>
                    <div className='clientContainer'>
                        <p style={{fontWeight: 'bold'}}>{client.isCompany? `${client.company.companyName}: ${client.accountAdmin.firstName} ${client.accountAdmin.lastName}` : client.profile.userDetails.firstName + ' ' + client.profile.userDetails.lastName}</p>
                        <p>{data.category}{' / '}{data.subCategory}<Link to={`./shipments/${shipmentData?.id}`}><span>{shipmentData?.uid}</span></Link></p>
                    </div>
                </div>
                <div>
                  {/* <span>{timeSince.days}d {timeSince.hours}:{timeSince.minutes}:{timeSince.seconds}</span> */}
                    <button className='closeButton' onClick={() => handleCloseCase()}>Close Case</button>
                </div>
            </div>
        
            <RenderMessages data={messages}/>  
            <form className='enterMessageForm' onSubmit={handleMessageSend}>
                <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder='Type a message...'
                className='messageInput'
                multiline={true}
                />

                <button type="submit" className='sendButton' disabled={!message}>
                    <i className="mdi mdi-send sendIcon" />
                </button>

            </form>
    </div>
    );
}

export default ChatSingleScreen