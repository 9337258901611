
import React from 'react';

import { auth } from 'firebase';
import './styles.scss';



const ChatSingleItem = ({ item }) => {
  
  
  const isCurrentUser = item.creator === auth.currentUser.uid;
  console.log(item.id, '=', item.creator);
  return (
    
        <div className='messageContainer' style={{ justifyContent: isCurrentUser ? 'flex-end' : 'flex-start' }}>
            <div className='bubbleSize'>
                <div className='bubble' style={{ backgroundColor: isCurrentUser ? '#FFCFCF' : '#f5f2f2' }}>
                    <div style={{ flexShrink: 1 }}>
                        <p className='messageText' style={{ color: isCurrentUser ? '#000' : '#262626' }}>
                            {item.message}
                        </p>
                    </div>
                    <div className='bubbleTime' >
                        <p className='bubbleTimeText' style={{ color: isCurrentUser ? 'gray' : 'black'  }}>
                            {item.creation? new Date(item.creation.seconds * 1000).toLocaleTimeString('en-GB').slice(0,5) : ''}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    
    
  );
};

export default ChatSingleItem;