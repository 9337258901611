import React, { useEffect, useMemo } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as yup from 'yup';

import QuoteForm from 'components/QuoteForm';
import { createShipment, fetchShipments, modifyShipmentWithNewQuote} from 'state/actions/shipments';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';



const schema = yup.object().shape({
  newPrice: yup.string().required(),
  selectedVehicle: yup.string().required(),
});

const Quote = () => {
  const { id } = useParams();

  const isEditing = useMemo(() => !!id, [id]);

  const { success, shipmentData, error } = useSelector(
    (state) => ({
      success: state.shipments.success,
      shipmentData: state.shipments.data.find((shipment) => shipment.id === id),
      error: state.shipments.error,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing) {
      if (!shipmentData) {
        dispatch(fetchShipments(id));
      }
    }
  }, [isEditing, id, shipmentData, dispatch]);

  const redirect = ((isEditing && error) || success) && (
    <Redirect to={paths.QUOTES} />
  );

  const editShipmentMessage = useFormatMessage('Quote.AddQuote');

  

  const onSubmitHandler = (value) => {
    console.log('hello there');
    const newShipment = {
      ...value,
      isEditing,
      id,
    };

    if (isEditing) {
      dispatch(modifyShipmentWithNewQuote(newShipment));
    } else {
      dispatch(createShipment(newShipment));
    }
  };

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {editShipmentMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !shipmentData ? (
          <ClipLoader />
        ) : (
          <QuoteForm
            isEditing={isEditing}
            shipment={
              isEditing
                ? shipmentData
                : {
                    name: '',
                    email: '',
                    location: '',
                    isAdmin: false,
                  }
            }
            onSubmitHandler={onSubmitHandler}
            schema={schema}
          />
        )}
      </section>
    </>
  );
};

export default Quote;
