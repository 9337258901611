/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
// import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import paths from 'pages/Router/paths';
import { usersCleanUp } from 'state/actions/users';
import { useFormatMessage } from 'hooks';
import { convertPdfToImages, modifyShipmentDelivery } from 'state/actions/shipments';
// import ErrorMessage from 'components/ErrorMessage';

import './DeliveredForm.scss';




/* eslint-disable */

const DeliveredForm = ({ isProfile, shipment, onSubmitHandler, schema }) => {
  const { loading, success } = useSelector(
    (state) => ({
      loading: state.shipments.loading,
      success: state.shipments.success,
    }),
    shallowEqual
  );
  const [signatureFile, setSignatureFile] = useState ('')
  const [signature, setSignature] = useState("")
  const [imagePDF, setImagePDF] = useState("")

  const dispatch = useDispatch();

  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: { ...shipment,
    file: signature},
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (success) {
      setValue('file', null);
    }
    return () => dispatch(usersCleanUp());
  }, [dispatch, success, setValue]);

  // const invalidEmailMessage = useFormatMessage('UserForm.invalidEmail');

  const imagePreviewUrl =
    watch('file') && watch('file')[0]
      ? URL.createObjectURL(watch('file')[0])
      : shipment.proofOfDeliveryUrl;

  const signaturePreviewUrl =
    signature != ''
      ? signature
      : shipment.proofOfDeliveryUrl;

  const goBackMessage = useFormatMessage('UserForm.goBack');

  const pickAnotherFileMessage = useFormatMessage('UserForm.pickAnotherFile');
  const pickFileMessage = useFormatMessage('UserForm.pickFile');

  // const emailMessage = useFormatMessage('UserForm.email');

  // const adminMessage = useFormatMessage('UserForm.admin');

  console.log(watch('file')[0])

  const cropperRef = useRef(null);
  const onCrop = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    const data = {"left":85,"top":77,"width":24,"height":10.1}
    // Width correct  data = {"left":85,"top":340,"width":24,"height":400}
    // const data = {"left":212,"top":308,"width":95,"height":41}
    cropper.setCropBoxData(data)
    const croppedDocument = cropper.getCroppedCanvas().toDataURL();
    const cleanerCD = croppedDocument.substring(22);
    console.log(croppedDocument)

    const b64toBlob = (b64Data, contentType, sliceSize=512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
    
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
    
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
    
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
    
      const blob = new Blob(byteArrays, {type: contentType});
      return blob;
    }
  
    const blob = b64toBlob(cleanerCD, 'image/png');
    const blobUrl = URL.createObjectURL(blob);
    
    setSignature(blobUrl)

    function urltoFile(url, filename, mimeType){
      return (fetch(url)
          .then(function(res){return res.arrayBuffer();})
          .then(function(buf){return new File([buf], filename,{type:mimeType});})
      );
    }
  
   
    //Usage example:
    const file2 = urltoFile(blobUrl, 'POD-signature.png','image/png')
    return file2
  }

  const handleConvert = async() => {
    const imageOfPdf = await convertPdfToImages(imagePreviewUrl);
    setImagePDF(imageOfPdf);
  } 

  const handleCrop = async() => {
    const newSignature = await onCrop();
    setSignatureFile(newSignature);
  }
  console.log(signature)
  var file1 = new File ([signatureFile], `${shipment.id}-POD-Signature.png`,{type:"image/png", lastModified:new Date().getTime()});
  console.log(file1)
  const newShipment ={
    file: file1,
    isEditing: true,
    id: shipment.id,
  }

  const handleSubmit1 = () => {
    dispatch(modifyShipmentDelivery(newShipment))
  }
 
  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default" />
                </span>
                {useFormatMessage('DeliveredForm.shipmentInfo')}
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                {/* {isEditing ? (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">{emailMessage}</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <></>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">{emailMessage}</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                           <></> 
                          </div>
                        </div>
                      </div>
                    </div>
                    {errors.email && (
                      <div className="field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <ErrorMessage text={invalidEmailMessage} />
                        </div>
                      </div>
                    )}
                  </>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('UserForm.name')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <></>
                      </div>
                    </div>
                  </div>
                </div>
                {errors.name && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('UserForm.location')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <></>
                      </div>
                    </div>
                  </div>
                </div>
                {!isProfile && (
                  <div className="field has-check is-horizontal">
                    <div className="field-label">
                      <label className="label">{adminMessage}</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="field">
                          <div className="control">
                            <label className="b-checkbox checkbox">
                              
                              <span className="check is-primary" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )} */}


                <hr />

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('DeliveredForm.proofOfDelivery')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="file has-name">
                        <label className="file-label">
                          <input
                            className="file-input"
                            type="file"
                            name="file"
                            ref={register}
                            accept=".pdf"
                          />
                          <span className="file-cta">
                            <span className="file-icon">
                              <i className="mdi mdi-upload" />
                            </span>
                            <span className="file-label">
                              {watch('file') && watch('file').file
                                ? pickAnotherFileMessage
                                : pickFileMessage}
                            </span>
                          </span>
                          <span className="file-name">
                            {watch('file') && watch('file')[0]?.name}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="button"
                            onClick={() => handleConvert()}
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                          >
                            <span>{useFormatMessage('DeliveredForm.convert')}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="button"
                            onClick={() => handleCrop()}
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                          >
                            <span>{useFormatMessage('DeliveredForm.crop')}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />
                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            onClick={() => handleSubmit1()}
                            type="button"
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                          >
                            <span>{useFormatMessage('UserForm.submit')}</span>
                          </button>
                        </div>
                        {!isProfile && (
                          <Link to={paths.DELIVERED} className="button">
                            {goBackMessage}
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="tile is-parent preview">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account default" />
                </span>
                {useFormatMessage('DeliveredForm.PODPreview')}
              </p>
            </header>
            <div className="card-content">
              {imagePreviewUrl && (
                <>
                  <div style={{overflow: "hidden"}} className="image has-max-width is-aligned-center">
                    <img
                      style={{}}
                      className="shipment-signature"
                      src={signaturePreviewUrl}
                      alt="Proof of delivery preview"
                    />
                  </div>
                  <div style={{overflow: "hidden"}} className="image has-max-width is-aligned-center">
                    <img
                      style={{}}
                      className="shipment-signature"
                      src={imagePDF}
                      alt="Proof of delivery preview"
                    />
                  </div>
                  <hr />
                  <Cropper
                    src={imagePDF}
                    style={{ height: 400, width: "100%", display: "none"}}
                    // Cropper.js options
                    initialAspectRatio={16 / 9}
                    guides={false}
                    
                    ref={cropperRef}
                  />
                </>
              )}


              

              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

DeliveredForm.propTypes = {
  shipment: PropTypes.shape({    
    proofOfDeliveryUrl: PropTypes.string,
  }).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object.isRequired,
  isProfile: PropTypes.bool,
};

DeliveredForm.defaultProps = {
  isProfile: false,
};

export default DeliveredForm;
