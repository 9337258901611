/* eslint-disable */
import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError } from 'utils';
import firebase, { functions } from 'firebase.js';
import { checkUserData } from './auth';
import {
  fetchCollection,
  fetchDocument,
  createDocument,
  deleteDocument,
} from '../api';
import { getFunctions, httpsCallable } from 'firebase/functions';



export const QUOTES_FETCH_DATA_INIT = createAction('QUOTES_FETCH_DATA_INIT');
export const QUOTES_FETCH_DATA_SUCCESS = createAction('QUOTES_FETCH_DATA_SUCCESS');
export const QUOTES_FETCH_DATA_FAIL = createAction('QUOTES_FETCH_DATA_FAIL');

export const QUOTES_DELETE_QUOTE_INIT = createAction('QUOTES_DELETE_QUOTE_INIT');
export const QUOTES_DELETE_QUOTE_SUCCESS = createAction('QUOTES_DELETE_QUOTE_SUCCESS');
export const QUOTES_DELETE_QUOTE_FAIL = createAction('QUOTES_DELETE_QUOTE_FAIL');

export const QUOTES_CREATE_QUOTE_INIT = createAction('QUOTES_CREATE_QUOTE_INIT');
export const QUOTES_CREATE_QUOTE_SUCCESS = createAction('QUOTES_CREATE_QUOTE_SUCCESS');
export const QUOTES_CREATE_QUOTE_FAIL = createAction('QUOTES_CREATE_QUOTE_FAIL');

export const QUOTES_MODIFY_QUOTE_INIT = createAction('QUOTES_MODIFY_QUOTE_INIT');
export const QUOTES_MODIFY_QUOTE_SUCCESS = createAction('QUOTES_MODIFY_QUOTE_SUCCESS');
export const QUOTES_MODIFY_QUOTE_FAIL = createAction('QUOTES_MODIFY_QUOTE_FAIL');

export const QUOTES_CLEAN_UP = createAction('QUOTES_CLEAN_UP');

export const QUOTES_CLEAR_DATA_LOGOUT = createAction('QUOTES_CLEAR_DATA_LOGOUT');

export const fetchShipments = (shipmentId = '') => {
  return async (dispatch, getState) => {
    dispatch(checkUserData());

    dispatch(QUOTES_FETCH_DATA_INIT());
    
    if (shipmentId) {
      let shipment;
      try {
        console.log('trying to fetch known shipments');
        shipment = await fetchDocument('shipments', shipmentId);
      } catch (error) {
        toastr.error('', error);
        return dispatch(QUOTES_FETCH_DATA_FAIL({ error }));
      }

      if (!shipment) {
        const errorMessage = 'Shipment not available';
        toastr.error('', errorMessage);
        return dispatch(QUOTES_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const shipments = getState().shipments.data;
      shipments.push(shipment);

      return dispatch(
        QUOTES_FETCH_DATA_SUCCESS({
          data: shipments,
        })
      );
    }

    

    let shipments;

    try {
      shipments = await fetchCollection('shipments');
      console.log('fetched shipments');
      console.log(shipments);
    } catch (error) {
      toastr.error('', error);
      return dispatch(QUOTES_FETCH_DATA_FAIL({ error }));
    }
    
    return dispatch(
      QUOTES_FETCH_DATA_SUCCESS({
        data: shipments
      })
    );
  };
};

export const fetchShipmentsForQuoting = (shipmentId = '') => {
  return async (dispatch, getState) => {
    dispatch(checkUserData());

    dispatch(QUOTES_FETCH_DATA_INIT());
    
    if (shipmentId) {
      let shipment;
      try {
        console.log('trying to fetch known shipments');
        shipment = await fetchDocument('shipments', shipmentId);
      } catch (error) {
        toastr.error('', error);
        return dispatch(QUOTES_FETCH_DATA_FAIL({ error }));
      }

      if (!shipment) {
        const errorMessage = 'Shipment not available';
        toastr.error('', errorMessage);
        return dispatch(QUOTES_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const shipments = getState().shipments.data;
      shipments.push(shipment);

      return dispatch(
        QUOTES_FETCH_DATA_SUCCESS({
          data: shipments,
        })
      );
    }

    

    let shipments;

    try {
      shipments = await fetchCollection('shipments');
      console.log('fetched shipments');
      console.log(shipments);
    } catch (error) {
      toastr.error('', error);
      return dispatch(QUOTES_FETCH_DATA_FAIL({ error }));
    }
    
    return dispatch(
      QUOTES_FETCH_DATA_SUCCESS({
        data: shipments.filter((shipment) => shipment.status === "TBQ"),
      })
    );
  };
};

const deleteLogo = (oldLogo) => {
  if (!oldLogo.includes('firebasestorage')) {
    return null;
  }
  const logoPath = oldLogo.split('shipments%2F').pop().split('?alt=media').shift();
  return firebase.storage().ref(`shipments/${logoPath}`).delete();
};

export const deleteShipment = (id) => {
  return async (dispatch, getState) => {
    dispatch(QUOTES_DELETE_QUOTE_INIT());
    const { locale } = getState().preferences;
    const { logoUrl } = getState()
      .shipments.data.filter((shipment) => shipment.id === id)
      .pop();

    const deleteLogoTask = logoUrl ? deleteLogo(logoUrl) : null;

    const deleteShipmentTask = deleteDocument('shipments', id);

    try {
      await Promise.all([deleteLogoTask, deleteShipmentTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        QUOTES_DELETE_QUOTE_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The shipment was deleted.');
    return dispatch(QUOTES_DELETE_QUOTE_SUCCESS({ id }));
  };
};

export const clearShipmentsDataLogout = () => {
  return (dispatch) => {
    dispatch(QUOTES_CLEAR_DATA_LOGOUT());
  };
};

const uploadLogo = (uid, file) => {
  const storageRef = firebase.storage().ref();

  const fileExtension = file.name.split('.').pop();

  const fileName = `${uid}.${fileExtension}`;

  return storageRef.child(`shipments/${fileName}`).put(file);
};

const getLogoUrl = (uid, file) => {
  const fileExtension = file.name.split('.').pop();

  const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;

  return `${bucketUrl}/o/shipments%2F${uid}_200x200.${fileExtension}?alt=media`;
};



export const createShipment = ({
  name,
  email,
  location,
  file,
  createdAt,
  isAdmin,
}) => {
  return async (dispatch, getState) => {
    dispatch(QUOTES_CREATE_QUOTE_INIT());
    const { locale } = getState().preferences;
    let response;
    try {
      const createShipmentAuth = firebase
        .functions()
        .httpsCallable('httpsCreateShipment');

      response = await createShipmentAuth({ email, isAdmin });
    } catch (error) {
      const errorMessage = firebaseError(error.message, locale);
      toastr.error('', errorMessage);
      return dispatch(
        QUOTES_CREATE_QUOTE_FAIL({
          error: errorMessage,
        })
      );
    }

    const { uid } = response.data;

    let uploadLogoTask = null;
    let logoUrl = null;
    if (file) {
      logoUrl = getLogoUrl(uid, file);
      uploadLogoTask = uploadLogo(uid, file);
    }
    const shipmentData = { name, email, location, logoUrl, createdAt, isAdmin };

    const createShipmentDbTask = createDocument('shipments', uid, shipmentData);

    const actionCodeSettings = {
      url: process.env.REACT_APP_LOGIN_PAGE_URL,
      handleCodeInApp: true,
    };

    const sendSignInLinkToEmailTask = firebase
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettings);

    try {
      await Promise.all([
        uploadLogoTask,
        createShipmentDbTask,
        sendSignInLinkToEmailTask,
      ]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        QUOTES_CREATE_QUOTE_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Shipment created successfully');
    return dispatch(QUOTES_CREATE_QUOTE_SUCCESS({ shipment: response.data }));
  };
};

export const createQuotePrice = (data) => {
  return async (dispatch, getState) => {
    dispatch(QUOTES_CREATE_QUOTE_INIT());
    const { locale } = getState().preferences;
    let response;
    try {
      const createPriceCalc = httpsCallable(functions, 'CreatePriceCalc');  
      response = await createPriceCalc(data);
    } catch (error) {
      const errorMessage = firebaseError(error.message, locale);
      toastr.error('', errorMessage);
      return dispatch(
        QUOTES_CREATE_QUOTE_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'price calculated successfully');
    console.log(response);
    dispatch(QUOTES_CREATE_QUOTE_SUCCESS());
    return response;
  };
};


export const shipmentsCleanUp = () => (dispatch) => dispatch(QUOTES_CLEAN_UP());
