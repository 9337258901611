import { auth, db } from 'firebase';
import { addDoc, collection, doc, onSnapshot, orderBy, query, serverTimestamp, updateDoc, where } from 'firebase/firestore';

/* eslint-disable */
export const chatsListener = (listener) => {
    console.log('chat listener active')
    const b = query(collection(db, "supportTicket"), where("status", "==", "open"), orderBy('lastUpdate', 'desc'));
    onSnapshot(b, (listener))
}

export const messagesListener = (listener, chatId) => {
    console.log('message listener active')
    const b = query(collection(db, `supportTicket/${chatId}/messages`), orderBy('creation', 'desc'));
    onSnapshot(b, (listener))
}

export const sendMessage = (chatId, message) =>{
    const docRef = doc(db, "supportTicket", chatId);
    const colRef = collection(docRef, "messages")
    addDoc(colRef, {
        creator: auth.currentUser.uid,
        message,
        creation: serverTimestamp(),
    });
    updateDoc(docRef, {
        lastUpdate: serverTimestamp(),
        lastMessage: message,
    });
}

export const createChat = (shipmentId, category, subCategory) => new Promise((resolve, reject) =>{
    const colRef = collection(db, "supportTicket");
    addDoc(colRef, {
        creator: auth.currentUser.uid,
        lastUpdate: serverTimestamp(),
        lastMessage: 'Chat created',
        shipmentId,
        category,
        subCategory,
        members: [auth.currentUser.uid],
        status: 'open'
    })
    .then(resolve)
    .catch(reject)
})

export const closeChat = (chatId) =>{
    const docRef = doc(db, "supportTicket", chatId);
    updateDoc(docRef, {
        status: 'closed'
    });
}