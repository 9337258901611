import { createReducer } from 'redux-act';

import {
  QUOTES_FETCH_DATA_INIT,
  QUOTES_FETCH_DATA_SUCCESS,
  QUOTES_FETCH_DATA_FAIL,
  QUOTES_DELETE_QUOTE_INIT,
  QUOTES_DELETE_QUOTE_SUCCESS,
  QUOTES_DELETE_QUOTE_FAIL,
  QUOTES_CREATE_QUOTE_INIT,
  QUOTES_CREATE_QUOTE_SUCCESS,
  QUOTES_CREATE_QUOTE_FAIL,
  QUOTES_MODIFY_QUOTE_INIT,
  QUOTES_MODIFY_QUOTE_SUCCESS,
  QUOTES_MODIFY_QUOTE_FAIL,
  QUOTES_CLEAN_UP,
  QUOTES_CLEAR_DATA_LOGOUT,
} from 'state/actions/quotes';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const quotesReducer = createReducer(
  {
    [QUOTES_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [QUOTES_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.data,
      loading: false,
      error: null,
    }),
    [QUOTES_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [QUOTES_DELETE_QUOTE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [QUOTES_DELETE_QUOTE_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [QUOTES_DELETE_QUOTE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [QUOTES_CREATE_QUOTE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [QUOTES_CREATE_QUOTE_SUCCESS]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
    }),
    [QUOTES_CREATE_QUOTE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [QUOTES_MODIFY_QUOTE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [QUOTES_MODIFY_QUOTE_SUCCESS]: (state, payload) => ({
      ...state,
      data: !state.data
        ? []
        : state.data.map((elem) => {
            if (elem.id === payload.id) {
              return {
                id: payload.id,
                creator: payload.shipment.creator,
                creation: payload.shipment.creation,
                status: payload.shipment.status,
                
                route:{
                  distance: payload.shipment.routeDistance,
                  duration: payload.shipment.routeDuration,
                },
                shipmentDetails:{
                  collectionDetails:{
                      contactPerson: payload.shipment.pickupContactName,
                      contactNumber: payload.shipment.pickupContactPhone,
                      address:{
                          buildingNo: payload.shipment.pickupBuildingNo,
                          street: payload.shipment.pickupStreet,
                          description: payload.shipment.pickupAddressDescription,
                          postcode: payload.shipment.pickupPostcode,
                      }
                  },
                  deliveryDetails:{
                      contactPerson: payload.shipment.deliveryContactName,
                      contactNumber: payload.shipment.deliveryContactPhone,
                      address:{
                          buildingNo: payload.shipment.deliveryBuildingNo,
                          street: payload.shipment.deliveryStreet,
                          description: payload.shipment.deliveryAddressDescription,
                          postcode: payload.shipment.deliveryPostcode,
                      }
                  }, 
                  dateAndTime:{
                      date: payload.shipment.startDate,
                      timeH: payload.shipment.collectionHour,
                      timeM: payload.shipment.collectionMinute,
                  },
                  shipmentOptions:{
                      urgentCollection: payload.shipment.urgent,
                      dedicatedVehicle: payload.shipment.dedicated,
                  }
                },
                
              };
            }
            return elem;
          }),
      loading: false,
      error: null,
      success: true,
    }),
    [QUOTES_MODIFY_QUOTE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [QUOTES_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [QUOTES_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
