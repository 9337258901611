import React, { useEffect, useMemo, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as yup from 'yup';

import ProcessForm from 'components/ProcessForm';
import { createShipment, fetchConfidentialShipmentData, fetchShipments, modifyShipmentWithProcess, SHIPMENTS_CLEAR_SECRET_DATA_LOGOUT } from 'state/actions/shipments';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';

/* eslint-disable */

const schema = yup.object().shape({
  reference: yup.string().required(),
  handler: yup.string().required(),
  processStatus: yup.string().required(),
  notes: yup.string(),

});

const ProcessShipment = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const isEditing = useMemo(() => !!id, [id]);
  console.log('fetching secret data');

  const [ refresh, setRefresh ]= useState(1);
  

  
  const { success, shipmentData, confidentialShipmentData, error } = useSelector(
    (state) => ({
      success: state.shipments.success,
      shipmentData: state.shipments.data.find((shipment) => shipment.id === id),
      confidentialShipmentData: state.secretShipments.data.find((shipment) => shipment.id === id),
      error: state.shipments.error,
    }),
    shallowEqual
  );
  
  console.log(!confidentialShipmentData? 'not loaded' : confidentialShipmentData.id);
  console.log(id);
  useEffect(() => {
    if (isEditing) {
      if (!shipmentData) {
        dispatch(fetchShipments(id));
      }
      if (!confidentialShipmentData) {
        dispatch(fetchConfidentialShipmentData(id));
      }
    }
  }, [isEditing, id, shipmentData, confidentialShipmentData, dispatch]);


  console.log(confidentialShipmentData);
  const redirect = ((isEditing && error) || success) && (
    <Redirect to={paths.PROCESS_SHIPMENTS} />
  );

  const editShipmentMessage = useFormatMessage('Process.processShipment');


  const onSubmitHandler = (value) => {
    const newShipment = {
      ...value,
      isEditing,
      id,
    };
    dispatch(SHIPMENTS_CLEAR_SECRET_DATA_LOGOUT());
    if (isEditing) {
      dispatch(modifyShipmentWithProcess(newShipment));
    } else {
      dispatch(createShipment(newShipment));
    }
  };

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {editShipmentMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !shipmentData || !confidentialShipmentData ? (
          <ClipLoader />
        ) : (
          <ProcessForm
            isEditing={isEditing}
            shipment={
              isEditing
                ? shipmentData
                : {
                    name: '',
                    email: '',
                    location: '',
                    isAdmin: false,
                  }
            }
            onSubmitHandler={onSubmitHandler}
            schema={schema}
            opsecShipmentData={confidentialShipmentData}
          />
        )}
      </section>
    </>
  );
};

export default ProcessShipment;
