import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setChats } from "state/actions/chats";

import { chatsListener } from "../services/chats";
/* eslint-disable */
export const useChats = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.auth.userData);
    const handleChatsChange = useCallback(
      (change) => {
        console.log("change: ",change)
        dispatch(setChats(change.docs.map(item => ({id: item.id, ...item.data()}))));
      },
      [dispatch],
    );
    
    useEffect(() => {
        let listenerInstance;
        if(currentUser != null){
            listenerInstance = chatsListener(handleChatsChange);
        }
        return () => {
            listenerInstance && listenerInstance();
        };
    }, [handleChatsChange, currentUser]);
    
};