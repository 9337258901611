import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import Login from 'pages/Login';
import Home from 'pages/Home';
import Users from 'pages/Users';
import Profile from 'pages/Profile';
import ResetPassword from 'pages/ResetPassword';
import NotFound from 'pages/NotFound';
import Shipment from 'pages/Shipment';
import Shipments from 'pages/Shipments';
import ProcessShipment from 'pages/ProcessShipment';
import ProcessShipments from 'pages/ProcessShipments';
import Quote from 'pages/AddQuote';
import Quotes from 'pages/AddQuotes';
import User from 'pages/User';
import Enroute from 'pages/Enroute';
import Enroutes from 'pages/Enroutes';
import Delivered from 'pages/Delivered';
import Delivereds from 'pages/Delivereds';
import Section from 'pages/Section';
import Submenu from 'pages/Submenu';
import Support from 'pages/Support';

import paths from './paths';
import PrivateRoute from './PrivateRoute';









const RouterComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={paths.LOGIN} component={Login} />
        <Route exact path={paths.RESET_PASSWORD} component={ResetPassword} />
        <PrivateRoute path={paths.ADD_USER} component={User} />
        <PrivateRoute path={paths.MODIFY_USER} component={User} />
        <PrivateRoute path={paths.USERS} component={Users} />
        <PrivateRoute path={paths.ADD_SHIPMENT} component={Shipment} />
        <PrivateRoute path={paths.MODIFY_SHIPMENT} component={Shipment} />
        <PrivateRoute path={paths.SHIPMENTS} component={Shipments} />
        <PrivateRoute path={paths.PROFILE} component={Profile} />
        <PrivateRoute path={paths.SECTION} component={Section} />
        <PrivateRoute path={paths.MODIFY_QUOTE} component={Quote} />
        <PrivateRoute path={paths.NEW_QUOTE} component={Quote} />
        <PrivateRoute path={paths.QUOTES} component={Quotes} />
        <PrivateRoute path={paths.MODIFY_PROCESS} component={ProcessShipment} />
        <PrivateRoute path={paths.PROCESS_SHIPMENTS} component={ProcessShipments} />
        <PrivateRoute path={paths.MODIFY_ENROUTES} component={Enroute} />
        <PrivateRoute path={paths.ENROUTES} component={Enroutes} />
        <PrivateRoute path={paths.MODIFY_DELIVERED} component={Delivered} />
        <PrivateRoute path={paths.DELIVERED} component={Delivereds} />
        <PrivateRoute path={paths.SUBMENU_2} component={Submenu} />
        <PrivateRoute path={paths.SUPPORT} component={Support} />
        <PrivateRoute path={paths.ROOT} component={Home} />
        
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default RouterComponent;
