import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  messagesListener } from "../services/chats";

/* eslint-disable */
export const useMessages = (chatId) => {
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.auth.userData.id);
    console.log('current User:',currentUser);
    const [messages, setMessages] = useState([]);
    const handleMessagesChange = useCallback(
      (change) => {
        console.log(change.docs.map(item => ({id: item.id, ...item.data()})));
        setMessages(change.docs.map(item => ({id: item.id, ...item.data()})));
      },
      [dispatch],
    );
    console.log(messages);
    useEffect(() => {
        let listenerInstance;
        if(currentUser != null){
            listenerInstance = messagesListener(handleMessagesChange, chatId);
        };
        return () => {
            listenerInstance && listenerInstance();
        };
    }, [handleMessagesChange, chatId, currentUser]);
    
    return { messages };
};