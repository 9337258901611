
import ChatListComponent from 'components/SupportChatList';
import React from 'react';


const Support = () => {
  

  return (
    <>
     <ChatListComponent />
    </>
  );
};

export default Support;
