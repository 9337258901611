/* eslint-disable */
import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError } from 'utils';
import firebase, { functions } from 'firebase.js';
import { checkUserData } from './auth';
import {
  fetchCollection,
  fetchSecretDocument,
  fetchDocument,
  createDocument,
  deleteDocument,
  updateDocument,
  updateSubDocument,
} from '../api';
import { httpsCallable } from 'firebase/functions';



/* eslint-disable */

const PDFJS = require("pdfjs-dist/legacy/build/pdf.js");
PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.13.216/pdf.worker.js`;

export const SHIPMENTS_FETCH_DATA_INIT = createAction('SHIPMENTS_FETCH_DATA_INIT');
export const SHIPMENTS_FETCH_DATA_SUCCESS = createAction('SHIPMENTS_FETCH_DATA_SUCCESS');
export const SHIPMENTS_FETCH_DATA_FAIL = createAction('SHIPMENTS_FETCH_DATA_FAIL');

export const SHIPMENTS_FETCH_SECRET_DATA_INIT = createAction('SHIPMENTS_FETCH_SECRET_DATA_INIT');
export const SHIPMENTS_FETCH_SECRET_DATA_SUCCESS = createAction('SHIPMENTS_FETCH_SECRET_DATA_SUCCESS');
export const SHIPMENTS_FETCH_SECRET_DATA_FAIL = createAction('SHIPMENTS_FETCH_SECRET_DATA_FAIL');
export const SHIPMENTS_MODIFY_SECRET_DATA_SUCCESS = createAction('SHIPMENTS_MODIFY_SECRET_DATA_SUCCESS');


export const SHIPMENTS_DELETE_SHIPMENT_INIT = createAction('SHIPMENTS_DELETE_SHIPMENT_INIT');
export const SHIPMENTS_DELETE_SHIPMENT_SUCCESS = createAction('SHIPMENTS_DELETE_SHIPMENT_SUCCESS');
export const SHIPMENTS_DELETE_SHIPMENT_FAIL = createAction('SHIPMENTS_DELETE_SHIPMENT_FAIL');

export const SHIPMENTS_CREATE_SHIPMENT_INIT = createAction('SHIPMENTS_CREATE_SHIPMENT_INIT');
export const SHIPMENTS_CREATE_SHIPMENT_SUCCESS = createAction('SHIPMENTS_CREATE_SHIPMENT_SUCCESS');
export const SHIPMENTS_CREATE_SHIPMENT_FAIL = createAction('SHIPMENTS_CREATE_SHIPMENT_FAIL');

export const SHIPMENTS_MODIFY_SHIPMENT_INIT = createAction('SHIPMENTS_MODIFY_SHIPMENT_INIT');
export const SHIPMENTS_MODIFY_SHIPMENT_SUCCESS = createAction('SHIPMENTS_MODIFY_SHIPMENT_SUCCESS');
export const SHIPMENTS_MODIFY_SHIPMENT_FAIL = createAction('SHIPMENTS_MODIFY_SHIPMENT_FAIL');

export const SHIPMENTS_CLEAN_UP = createAction('SHIPMENTS_CLEAN_UP');

export const SHIPMENTS_CLEAR_DATA_LOGOUT = createAction('SHIPMENTS_CLEAR_DATA_LOGOUT');
export const SHIPMENTS_CLEAR_SECRET_DATA_LOGOUT = createAction('SHIPMENTS_CLEAR_SECRET_DATA_LOGOUT');


export const fetchShipments = (shipmentId = '') => {
  return async (dispatch, getState) => {
    dispatch(checkUserData());

    dispatch(SHIPMENTS_FETCH_DATA_INIT());
    
    if (shipmentId) {
      let shipment;
      try {
        console.log('trying to fetch known shipments');
        shipment = await fetchDocument('shipments', shipmentId);
      } catch (error) {
        toastr.error('', error);
        return dispatch(SHIPMENTS_FETCH_DATA_FAIL({ error }));
      }

      if (!shipment) {
        const errorMessage = 'Shipment not available';
        toastr.error('', errorMessage);
        return dispatch(SHIPMENTS_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const shipments = getState().shipments.data;
      shipments.push(shipment);

      return dispatch(
        SHIPMENTS_FETCH_DATA_SUCCESS({
          data: shipments,
        })
      );
    }

    

    let shipments;

    try {
      shipments = await fetchCollection('shipments');
      console.log('fetched shipments');
      console.log(shipments);
    } catch (error) {
      toastr.error('', error);
      return dispatch(SHIPMENTS_FETCH_DATA_FAIL({ error }));
    }
    
    return dispatch(
      SHIPMENTS_FETCH_DATA_SUCCESS({
        data: shipments
      })
    );
  };
};

export const fetchShipmentsForQuoting = (shipmentId = '') => {
  return async (dispatch, getState) => {
    dispatch(checkUserData());

    dispatch(SHIPMENTS_FETCH_DATA_INIT());
    
    if (shipmentId) {
      let shipment;
      try {
        console.log('trying to fetch known shipments');
        shipment = await fetchDocument('shipments', shipmentId);
      } catch (error) {
        toastr.error('', error);
        return dispatch(SHIPMENTS_FETCH_DATA_FAIL({ error }));
      }

      if (!shipment) {
        const errorMessage = 'Shipment not available';
        toastr.error('', errorMessage);
        return dispatch(SHIPMENTS_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const shipments = getState().shipments.data;
      shipments.push(shipment);

      return dispatch(
        SHIPMENTS_FETCH_DATA_SUCCESS({
          data: shipments,
        })
      );
    }

    

    let shipments;

    try {
      shipments = await fetchCollection('shipments');
      console.log('fetched shipments');
      console.log(shipments);
    } catch (error) {
      toastr.error('', error);
      return dispatch(SHIPMENTS_FETCH_DATA_FAIL({ error }));
    }
    
    return dispatch(
      SHIPMENTS_FETCH_DATA_SUCCESS({
        data: shipments.filter((shipment) => shipment.status === "TBQ"),
      })
    );
  };
};

export const fetchShipmentsForProcessing = (shipmentId = '') => {
  return async (dispatch, getState) => {
    dispatch(checkUserData());

    dispatch(SHIPMENTS_FETCH_DATA_INIT());
    
    if (shipmentId) {
      let shipment;
      try {
        console.log('trying to fetch known shipments');
        shipment = await fetchDocument('shipments', shipmentId);
      } catch (error) {
        toastr.error('', error);
        return dispatch(SHIPMENTS_FETCH_DATA_FAIL({ error }));
      }

      if (!shipment) {
        const errorMessage = 'Shipment not available';
        toastr.error('', errorMessage);
        return dispatch(SHIPMENTS_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const shipments = getState().shipments.data;
      shipments.push(shipment);

      return dispatch(
        SHIPMENTS_FETCH_DATA_SUCCESS({
          data: shipments,
        })
      );
    }

    

    let shipments;

    try {
      shipments = await fetchCollection('shipments');
      console.log('fetched shipments');
      console.log(shipments);
    } catch (error) {
      toastr.error('', error);
      return dispatch(SHIPMENTS_FETCH_DATA_FAIL({ error }));
    }
    
    return dispatch(
      SHIPMENTS_FETCH_DATA_SUCCESS({
        data: shipments.filter((shipment) => shipment.status === "PC"),
      })
    );
  };
};

export const fetchShipmentsEnroute = (shipmentId = '') => {
  return async (dispatch, getState) => {
    dispatch(checkUserData());

    dispatch(SHIPMENTS_FETCH_DATA_INIT());
    
    if (shipmentId) {
      let shipment;
      try {
        console.log('trying to fetch known shipments');
        shipment = await fetchDocument('shipments', shipmentId);
      } catch (error) {
        toastr.error('', error);
        return dispatch(SHIPMENTS_FETCH_DATA_FAIL({ error }));
      }

      if (!shipment) {
        const errorMessage = 'Shipment not available';
        toastr.error('', errorMessage);
        return dispatch(SHIPMENTS_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const shipments = getState().shipments.data;
      shipments.push(shipment);

      return dispatch(
        SHIPMENTS_FETCH_DATA_SUCCESS({
          data: shipments,
        })
      );
    }

    

    let shipments;

    try {
      shipments = await fetchCollection('shipments');
      console.log('fetched shipments');
      console.log(shipments);
    } catch (error) {
      toastr.error('', error);
      return dispatch(SHIPMENTS_FETCH_DATA_FAIL({ error }));
    }
    
    return dispatch(
      SHIPMENTS_FETCH_DATA_SUCCESS({
        data: shipments.filter((shipment) => shipment.status === "EN"),
      })
    );
  };
};

export const fetchShipmentsDelivered = (shipmentId = '') => {
  return async (dispatch, getState) => {
    dispatch(checkUserData());

    dispatch(SHIPMENTS_FETCH_DATA_INIT());
    
    if (shipmentId) {
      let shipment;
      try {
        console.log('trying to fetch known shipments');
        shipment = await fetchDocument('shipments', shipmentId);
      } catch (error) {
        toastr.error('', error);
        return dispatch(SHIPMENTS_FETCH_DATA_FAIL({ error }));
      }

      if (!shipment) {
        const errorMessage = 'Shipment not available';
        toastr.error('', errorMessage);
        return dispatch(SHIPMENTS_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const shipments = getState().shipments.data;
      shipments.push(shipment);

      return dispatch(
        SHIPMENTS_FETCH_DATA_SUCCESS({
          data: shipments,
        })
      );
    }

    

    let shipments;

    try {
      shipments = await fetchCollection('shipments');
      console.log('fetched shipments');
      console.log(shipments);
    } catch (error) {
      toastr.error('', error);
      return dispatch(SHIPMENTS_FETCH_DATA_FAIL({ error }));
    }
    
    return dispatch(
      SHIPMENTS_FETCH_DATA_SUCCESS({
        data: shipments.filter((shipment) => shipment.status === "D"),
      })
    );
  };
};

export const fetchConfidentialShipmentData = (shipmentId = '') => {
  return async (dispatch, getState) => {
    dispatch(SHIPMENTS_FETCH_SECRET_DATA_INIT());
    if (shipmentId) {
      let secretShipmentData;
      try {
        secretShipmentData = await fetchSecretDocument('shipments', shipmentId);
      } catch (error) {
        toastr.error('', error);
        return dispatch(SHIPMENTS_FETCH_SECRET_DATA_FAIL({ error }));
      }
      const shipments = getState().secretShipments.data;
      shipments.push(secretShipmentData);

      return dispatch(
        SHIPMENTS_FETCH_SECRET_DATA_SUCCESS({
          data: shipments,
        })
      );
    }
  };
};


const deleteProofOfDelivery = (oldProofOfDelivery) => {
  if (!oldProofOfDelivery.includes('firebasestorage')) {
    return null;
  }
  const proofOfDeliveryPath = oldProofOfDelivery.split('shipments%2F').pop().split('?alt=media').shift();
  return firebase.storage().ref(`shipments/${proofOfDeliveryPath}`).delete();
};

export const deleteShipment = (id) => {
  return async (dispatch, getState) => {
    dispatch(SHIPMENTS_DELETE_SHIPMENT_INIT());
    const { locale } = getState().preferences;
    const { proofOfDeliveryUrl } = getState()
      .shipments.data.filter((shipment) => shipment.id === id)
      .pop();

    const deleteProofOfDeliveryTask = proofOfDeliveryUrl ? deleteProofOfDelivery(proofOfDeliveryUrl) : null;

    const deleteShipmentTask = deleteDocument('shipments', id);

    try {
      await Promise.all([deleteProofOfDeliveryTask, deleteShipmentTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SHIPMENTS_DELETE_SHIPMENT_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The shipment was deleted.');
    return dispatch(SHIPMENTS_DELETE_SHIPMENT_SUCCESS({ id }));
  };
};

export const clearShipmentsDataLogout = () => {
  return (dispatch) => {
    dispatch(SHIPMENTS_CLEAR_DATA_LOGOUT());
  };
};

const uploadProofOfDelivery = (uid, file) => {
  const storageRef = firebase.storage().ref();

  const fileExtension = file.name.split('.').pop();

  const fileName = `${uid}.${fileExtension}`;

  return storageRef.child(`shipments/${fileName}`).put(file);
};

const getProofOfDeliveryUrl = (uid, file) => {
  const fileExtension = file.name.split('.').pop();

  const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;

  return `${bucketUrl}/o/shipments%2F${uid}.${fileExtension}?alt=media`;
};

export const createShipment = ({
  name,
  email,
  location,
  file,
  createdAt,
  isAdmin,
}) => {
  return async (dispatch, getState) => {
    dispatch(SHIPMENTS_CREATE_SHIPMENT_INIT());
    const { locale } = getState().preferences;
    let response;
    try {
      const createShipmentAuth = firebase
        .functions()
        .httpsCallable('httpsCreateShipment');

      response = await createShipmentAuth({ email, isAdmin });
    } catch (error) {
      const errorMessage = firebaseError(error.message, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SHIPMENTS_CREATE_SHIPMENT_FAIL({
          error: errorMessage,
        })
      );
    }

    const { uid } = response.data;

    let uploadProofOfDeliveryTask = null;
    let proofOfDeliveryUrl = null;
    if (file) {
      proofOfDeliveryUrl = getProofOfDeliveryUrl(uid, file);
      uploadProofOfDeliveryTask = uploadProofOfDelivery(uid, file);
    }
    const shipmentData = { name, email, location, proofOfDeliveryUrl, createdAt, isAdmin };

    const createShipmentDbTask = createDocument('shipments', uid, shipmentData);

    const actionCodeSettings = {
      url: process.env.REACT_APP_LOGIN_PAGE_URL,
      handleCodeInApp: true,
    };

    const sendSignInLinkToEmailTask = firebase
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettings);

    try {
      await Promise.all([
        uploadProofOfDeliveryTask,
        createShipmentDbTask,
        sendSignInLinkToEmailTask,
      ]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SHIPMENTS_CREATE_SHIPMENT_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Shipment created successfully');
    return dispatch(SHIPMENTS_CREATE_SHIPMENT_SUCCESS({ shipment: response.data }));
  };
};

export const modifyShipmentWithNewQuote = ({
  newPrice,
  selectedVehicle,
  id,
}) => {
  return async (dispatch, getState) => {
    const price = newPrice * 100;
    const tax = newPrice * 100 / 5;
    dispatch(SHIPMENTS_MODIFY_SHIPMENT_INIT());
    const { locale } = getState().preferences;
    getState().shipments.data.find((thisShipment) => thisShipment.id === id);
    

    const data = {
      shipment: {
      id: id,
      price: price,
      tax: tax,
      allocatedVehicle:selectedVehicle,
      }
    }
    const quoteIssued = httpsCallable(functions, 'quoteIssued');

    try {
      await Promise.all([quoteIssued(data)]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SHIPMENTS_MODIFY_SHIPMENT_FAIL({
          error: errorMessage,
        })
      );
    }

    // const { uid } = firebase.auth().currentShipment;

    // if (id === uid) {
    //   dispatch(AUTH_UPDATE_SHIPMENT_DATA({ ...shipmentData, id }));
    // }    
    
    toastr.success('', 'Shipment updated successfully');
    
    return dispatch(SHIPMENTS_MODIFY_SHIPMENT_SUCCESS());
  };
};


export const modifyShipment = ({
  creator,
  creation,
  status,
  routeDistance,
  routeDuration,

  pickupContactName,
  pickupContactPhone,
  pickupBuildingNo,
  pickupStreet,
  pickupAddressDescription,
  pickupPostcode,
  deliveryContactName,
  deliveryContactPhone,
  deliveryBuildingNo,
  deliveryStreet,
  deliveryAddressDescription,
  deliveryPostcode,
  startDate,
  collectionHour,
  collectionMinute,
  urgent,
  dedicated,
  cat,
  cdt,
  dat,
  dct,

  id,
  isEditing,
  isProfile,
}) => {
  return async (dispatch, getState) => {
    dispatch(SHIPMENTS_MODIFY_SHIPMENT_INIT());
    const { locale } = getState().preferences;
    getState().shipments.data.find((thisShipment) => thisShipment.id === id);
    

    const shipmentData = {
      creator,
      creation,
      status,
      route:{
        distance: routeDistance,
        duration: routeDuration,
      },
      routeTime:{
        cat: cat == null? null : cat,
        cdt: cdt == null? null : cdt,
        dat: dat == null? null : dat,
        dct: dct == null? null : dct,
      },
      shipmentDetails:{
        collectionDetails:{
            contactPerson: pickupContactName,
            contactNumber: pickupContactPhone,
            address:{
                buildingNo: pickupBuildingNo,
                street: pickupStreet,
                description: pickupAddressDescription,
                postcode: pickupPostcode,
            }
        },
        deliveryDetails:{
            contactPerson: deliveryContactName,
            contactNumber: deliveryContactPhone,
            address:{
                buildingNo: deliveryBuildingNo,
                street: deliveryStreet,
                description: deliveryAddressDescription,
                postcode: deliveryPostcode,
            }
        }, 
        dateAndTime:{
            date: startDate,
            timeH: collectionHour,
            timeM: collectionMinute,
        },
        shipmentOptions:{
            urgentCollection: urgent,
            dedicatedVehicle: dedicated,
        }
      },
      
    };
    const updateShipmentDbTask = updateDocument('shipments', id, shipmentData);

    try {
      await Promise.all([updateShipmentDbTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SHIPMENTS_MODIFY_SHIPMENT_FAIL({
          error: errorMessage,
        })
      );
    }

    // const { uid } = firebase.auth().currentShipment;

    // if (id === uid) {
    //   dispatch(AUTH_UPDATE_SHIPMENT_DATA({ ...shipmentData, id }));
    // }

    if (isProfile) {
      toastr.success('', 'Profile updated successfully');
    } else if (isEditing) {
      toastr.success('', 'Shipment updated successfully');
    }
    return dispatch(SHIPMENTS_MODIFY_SHIPMENT_SUCCESS({ shipment: shipmentData, id }));
  };
};

export const modifyShipmentDelivery = ({
  file,

  id,
  isEditing,
  isProfile,
}) => {
  return async (dispatch, getState) => {
    dispatch(SHIPMENTS_MODIFY_SHIPMENT_INIT());
    const { locale } = getState().preferences;
    const shipment = getState().shipments.data.find((thisShipment) => thisShipment.id === id);
    const { proofOfDeliveryUrl } = shipment;
    let deleteProofOfDeliveryTask;
    let uploadProofOfDeliveryTask;
    let newProofOfDeliveryUrl = null;
    if (file) {
      newProofOfDeliveryUrl = getProofOfDeliveryUrl(id, file);
      deleteProofOfDeliveryTask = proofOfDeliveryUrl && deleteProofOfDelivery(proofOfDeliveryUrl);
      uploadProofOfDeliveryTask = uploadProofOfDelivery(id, file);
    }
    console.log('I decided to get :',proofOfDeliveryUrl || newProofOfDeliveryUrl)
    const shipmentData = {
      proofOfDeliveryUrl: proofOfDeliveryUrl || newProofOfDeliveryUrl,
      
    };
    const updateShipmentDbTask = updateDocument('shipments', id, shipmentData);

    try {
      await Promise.all([ deleteProofOfDeliveryTask, uploadProofOfDeliveryTask, updateShipmentDbTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SHIPMENTS_MODIFY_SHIPMENT_FAIL({
          error: errorMessage,
        })
      );
    }

    // const { uid } = firebase.auth().currentShipment;

    // if (id === uid) {
    //   dispatch(AUTH_UPDATE_SHIPMENT_DATA({ ...shipmentData, id }));
    // }

    if (isProfile) {
      toastr.success('', 'Profile updated successfully');
    } else if (isEditing) {
      toastr.success('', 'Shipment updated successfully');
    }
    return dispatch(SHIPMENTS_MODIFY_SHIPMENT_SUCCESS({ shipment: shipmentData, id }));
  };
};

export const modifyShipmentEnroute = ({
  Cat,
  Cdt,
  Dat,
  Dct,
  statusD,
  id,
}) => {
  return async (dispatch, getState) => {
    dispatch(SHIPMENTS_MODIFY_SHIPMENT_INIT());
    const { locale } = getState().preferences;
    getState().shipments.data.find((thisShipment) => thisShipment.id === id);
    const newStatus = statusD? 'D' : 'EN';
    console.log(id,Cat,Cdt,Dat,Dct,newStatus)
    const shipmentData = {
      status: newStatus,
      routeTime:{
        cat: Cat == null? null :`${Cat}`,
        cdt: Cdt == null? null :`${Cdt}`,
        dat: Dat == null? null :`${Dat}`,
        dct: Dct == null? null :`${Dct}`,
      }
      
    };
    const updateShipmentDbTask = updateDocument('shipments', id, shipmentData);
    try {
      await Promise.all([updateShipmentDbTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SHIPMENTS_MODIFY_SHIPMENT_FAIL({
          error: errorMessage,
        })
      );
    }
    if (statusD){
      console.log('firing invoicenumber')
      const attachInvoiceNumber = httpsCallable(functions, 'invoiceNumberAllocation')
      attachInvoiceNumber(id)
    }
    // const { uid } = firebase.auth().currentShipment;

    // if (id === uid) {
    //   dispatch(AUTH_UPDATE_SHIPMENT_DATA({ ...shipmentData, id }));
    // }

    
    toastr.success('', 'Shipment updated successfully');
    
    
    return dispatch(SHIPMENTS_MODIFY_SHIPMENT_SUCCESS({ shipment: shipmentData, id }));
  };
};

export const modifyShipmentWithProcess = ({
  processStatus,
  handler,
  reference,
  notes,

  id,
  isEditing,
  isProfile,
}) => {
  return async (dispatch, getState) => {
    dispatch(SHIPMENTS_MODIFY_SHIPMENT_INIT());
    const { locale } = getState().preferences;
    getState().shipments.data.find((thisShipment) => thisShipment.id === id);
    

    const shipmentData = {
      processStatus,
      
      
      
    };
    const privateShipmentData = {
      id,
      handler,
      reference,
      notes,
    };
    const updateShipmentPrivateDbTask = updateSubDocument('shipments', id, privateShipmentData);
    const updateShipmentDbTask = updateDocument('shipments', id, shipmentData);
    try {
      await Promise.all([updateShipmentPrivateDbTask, updateShipmentDbTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SHIPMENTS_MODIFY_SHIPMENT_FAIL({
          error: errorMessage,
        })
      );
    }

    // const { uid } = firebase.auth().currentShipment;

    // if (id === uid) {
    //   dispatch(AUTH_UPDATE_SHIPMENT_DATA({ ...shipmentData, id }));
    // }

    if (isProfile) {
      toastr.success('', 'Profile updated successfully');
    } else if (isEditing) {
      toastr.success('', 'Shipment updated successfully');
    }
    dispatch(SHIPMENTS_MODIFY_SECRET_DATA_SUCCESS({shipment: privateShipmentData, id}));
    return dispatch(SHIPMENTS_MODIFY_SHIPMENT_SUCCESS({ shipment: shipmentData, id }));
  };
};

export const modifyShipmentStatus = ({ row }) => {
  const { id } = row.original;
  return async (dispatch, getState) => {
    dispatch(SHIPMENTS_MODIFY_SHIPMENT_INIT());
    const { locale } = getState().preferences;
    getState().shipments.data.find((thisShipment) => thisShipment.id === id);
    const shipmentData = {
      status: "EN",
    };
    const updateShipmentDbTask = updateDocument('shipments', id, shipmentData);
    try {
      await Promise.all([updateShipmentDbTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SHIPMENTS_MODIFY_SHIPMENT_FAIL({
          error: errorMessage,
        })
      );
    }

    // const { uid } = firebase.auth().currentShipment;

    // if (id === uid) {
    //   dispatch(AUTH_UPDATE_SHIPMENT_DATA({ ...shipmentData, id }));
    // }

    
    toastr.success('', 'Shipment updated successfully');
    
    return dispatch(SHIPMENTS_MODIFY_SHIPMENT_SUCCESS({ shipment: shipmentData, id }));
  };
};

export const shipmentsCleanUp = () => (dispatch) => dispatch(SHIPMENTS_CLEAN_UP());



export const convertPdfToImages = async (file) => {
  console.log(file)
  PDFJS.disableWorker = true;
  const pdf = await PDFJS.getDocument(file).promise;
  const canvas = document.createElement("canvas");
  
  const page = await pdf.getPage(1);
  const viewport = page.getViewport({ scale: 5 });
  const context = canvas.getContext("2d");
  canvas.height = viewport.height;
  canvas.width = viewport.width;
  console.log("about to render")
  await page.render({ canvasContext: context, viewport: viewport }).promise;
  const image = (canvas.toDataURL({type: "string"}));
  const cleanerImage = image.substring(22);


  const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  const blob = b64toBlob(cleanerImage, 'image/png');
  const blobUrl = URL.createObjectURL(blob);
  return blobUrl;

};

