/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';

import paths from 'pages/Router/paths';
import { shipmentsCleanUp } from 'state/actions/shipments';
import { useFormatMessage } from 'hooks';

// import ErrorMessage from 'components/ErrorMessage';
import Table from '../Table';
import './ProcessForm.scss';

/* eslint-disable */

const ProcessForm = ({ isProfile, shipment, onSubmitHandler, schema, opsecShipmentData }) => {
  const { loading, success } = useSelector(
    (state) => ({
      loading: state.shipments.loading,
      success: state.shipments.success,
    }),
    shallowEqual
  );
  
  const dispatch = useDispatch();

  
  console.log();
  const shipmentData = {
    uid: shipment.uid,
    status: shipment.status,
    distance: shipment.route.distance,
    duration: shipment.route.duration,
    cargo: shipment.cargoDetails,
    dedicated: shipment.shipmentDetails.shipmentOptions.dedicatedVehicle,
    urgent: shipment.shipmentDetails.shipmentOptions.urgentCollection,
    countdown: shipment.shipmentDetails.dateAndTime.countdown,
    date: shipment.shipmentDetails.dateAndTime.date,
    processStatus2: shipment.processStatus
  };
  console.log(shipmentData.status);
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: { 
      status: shipment.status,
      handler: opsecShipmentData.handler,
      notes: opsecShipmentData.notes,
      reference: opsecShipmentData.reference,
      processStatus: shipment.processStatus,
      

    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (success) {
      setValue('file', null);
    }
    return () => dispatch(shipmentsCleanUp());
  }, [dispatch, success, setValue]);

  const goBackMessage = useFormatMessage('ShipmentForm.goBack');

  // const pickAnotherFileMessage = useFormatMessage('ShipmentForm.pickAnotherFile');
  // const pickFileMessage = useFormatMessage('ShipmentForm.pickFile');

  const calculateTimeLeft = () => {
    const now = new Date()
    const collection = new Date(shipmentData.date)
    const difference = +collection - +now;
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];
  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{',\xa0'}
      </span>
    );
  });

  const columns = [
    {
      Header: useFormatMessage('Item.Quantity'),
      accessor: 'amount',
    },
    {
      Header: useFormatMessage('Item.Name'),
      accessor: 'name',
    },
    {
      Header: useFormatMessage('Item.Description'),
      accessor: 'description',
    },
    {
      Header: useFormatMessage('Item.Length'),
      accessor: 'length',
    },
    {
      Header: useFormatMessage('Item.Width'),
      accessor: 'width',
    },
    {
      Header: useFormatMessage('Item.Height'),
      accessor: 'height',
    },
    {
      Header: useFormatMessage('Item.Weight'),
      accessor: 'weight',
    },
    {
      Header: useFormatMessage('Item.Value'),
      accessor: 'value',
    },
  ];

  const data = shipmentData.cargo.items;

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-dolly default" />
                </span>
                {useFormatMessage('ProcessForm.ProcessShipment')}
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ProcessForm.shipmentProcessStatus')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                           {shipmentData.processStatus2}
                        </label>
                      </div>
                    </div>
                  </div>
                </div> 
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ProcessForm.RemainingTime')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {timerComponents.length ? timerComponents : <span>Time's up!</span>}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <p className="card-header-title">
                  {useFormatMessage('ProcessForm.ShipmentHandling')}
                </p>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                    {useFormatMessage('ProcessForm.HandlingType')}
                    </label>
                  </div>
                  <div className="field-body">  
                      <div className="control">
                      <select 
                        onChange={(val) => setValue('select', val.target.value,{ shouldValidate: true })} 
                        className="input" 
                        name="handler"
                        ref={register}>
                        <option selected value="Select">Select Handler</option>
                        <option value="lcc" >LCC Fleet</option>
                        <option value="cx" >Contracted (CX)</option>
                        <option value="other" >other</option>
                      </select>
                    </div>
                  </div>
                </div>
                
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                    {useFormatMessage('ProcessForm.HandlingReference')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          type="text"
                          className="input"
                          name="reference"
                          placeholder="Enter reference"
                          ref={register}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <p className="card-header-title">
                  {useFormatMessage('ProcessForm.AddNotes')}
                </p>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ProcessForm.Notes')}
                    </label>
                  </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="field">
                          <div className="control">
                          <input
                            type="text"
                            className="input"
                            name="notes"
                            placeholder="Enter notes"
                            ref={register}
                          />
                          </div>                        
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <p className="card-header-title">
                  {useFormatMessage('ProcessForm.SetNextStatus')}
                </p>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                    {useFormatMessage('ProcessForm.NextProcessStatus')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field is-grouped">  
                      <div className="control">
                        <select 
                          onChange={(val) => setValue('status', val.target.value,{ shouldValidate: true })} 
                          className="input" 
                          name="processStatus"
                          ref={register}>
                          <option selected value="listed">Listed</option>
                          <option value="booked" >Booked</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="field is-horizontal">
                  <div className="field-label" />
                  
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="submit"
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                          >
                            <span>{useFormatMessage('ShipmentForm.submit')}</span>
                          </button>
                        </div>
                        {!isProfile && (
                          <Link to={paths.PROCESS_SHIPMENTS} className="button">
                            {goBackMessage}
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="tile is-parent preview">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-map-marker-path default" />
                </span>
                {useFormatMessage('ProcessForm.ShipmentDetails')}
              </p>
            </header>
            <div className="card-content">
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">
                    {useFormatMessage('ShipmentForm.shipmentId')}
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <label className="input is-static">
                        {shipmentData.uid}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ShipmentForm.shipmentStatus')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.status}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ShipmentForm.shipmentDistance')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <label className="input is-static">
                            {shipmentData.distance}
                          </label>                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ShipmentForm.shipmentDuration')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.duration}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {shipmentData.urgent === true 
                ? <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        {useFormatMessage('QuoteForm.shipmentUrgent')}
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <label className="input is-static">
                            true
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                :<></>
                }
                {shipmentData.dedicated === true 
                ? <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        {useFormatMessage('QuoteForm.shipmentDedicated')}
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <label className="input is-static">
                            true
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                :<></>
                }
              <hr />
              
              <p className="card-header-title">
                  {useFormatMessage('QuoteForm.shipmentCargo')}
              </p>

              {shipmentData.cargo.type === "standard"?
              <>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('QuoteForm.cargoDescription')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.cargo.description}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('QuoteForm.cargoTotalWeight')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.cargo.totalWeight}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('QuoteForm.cargoLongestLength')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.cargo.longestLength}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('QuoteForm.cargoValue')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.cargo.value}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              :
              <>
              </>
              } 
              {shipmentData.cargo.type === "moreSpecific"?
              <>
                <div className="b-table">
                  <Table columns={columns} data={data} />
                </div>
              </>
              :
              <>
              </>
              }        
              {shipmentData.cargo.type === "vehicle"?
              <>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('QuoteForm.cargoDescription')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.cargo.description}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('QuoteForm.cargoSelectedVehicle')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.cargo.vehicle}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              :
              <>
              </>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ProcessForm.propTypes = {
  shipment: PropTypes.shape({
    notes: PropTypes.string.isRequired,
    handler: PropTypes.string.isRequired,
    reference: PropTypes.string.isRequired,
    processStatus: PropTypes.string.isRequired,
  }).isRequired,

  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object.isRequired,
  isProfile: PropTypes.bool,
};

ProcessForm.defaultProps = {
  isProfile: false,
};

export default ProcessForm;
