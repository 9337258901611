/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';

import paths from 'pages/Router/paths';
import { modifyShipmentEnroute, shipmentsCleanUp } from 'state/actions/shipments';
import { useFormatMessage } from 'hooks';
import DatePicker from 'react-datepicker';

// import ErrorMessage from 'components/ErrorMessage';
import Table from '../Table';
import './ProcessForm.scss';

/* eslint-disable */

const EnrouteForm = ({ isProfile, shipment, onSubmitHandler, schema }) => {
  const { loading, success } = useSelector(
    (state) => ({
      loading: state.shipments.loading,
      success: state.shipments.success,
    }),
    shallowEqual
  );
  
  const dispatch = useDispatch();


  
  console.log();
  const shipmentData = {
    uid: shipment.uid,
    status: shipment.status,
    distance: shipment.route.distance,
    duration: shipment.route.duration,
    cargo: shipment.cargoDetails,
    dedicated: shipment.shipmentDetails.shipmentOptions.dedicatedVehicle,
    urgent: shipment.shipmentDetails.shipmentOptions.urgentCollection,
    countdown: shipment.shipmentDetails.dateAndTime.countdown,
    date: shipment.shipmentDetails.dateAndTime.date,
    processStatus2: shipment.processStatus,
    dataCAT: shipment.routeTime.cat == null? null : new Date(shipment.routeTime.cat),
    dataCDT: shipment.routeTime.cdt == null? null : new Date(shipment.routeTime.cdt),
    dataDAT: shipment.routeTime.dat == null? null : new Date(shipment.routeTime.dat),
    dataDCT: shipment.routeTime.dct == null? null : new Date(shipment.routeTime.dct),
  };
  console.log(shipment.id)
  const id = shipment.id;
  const [cat, setCat] = useState(shipmentData.dataCAT);
  const [cdt, setCdt] = useState(shipmentData.dataCDT);
  const [dat, setDat] = useState(shipmentData.dataDAT);
  const [dct, setDct] = useState(shipmentData.dataDCT);
  const [statusD, setStatusD] = useState(false);
  

  var Cat = cat == null? null: cat;
  var Cdt = cdt == null? null: cdt;
  var Dat = dat == null? null: dat;
  var Dct = dct == null? null: dct;

  console.log(shipmentData.status);
  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: { 
      status: shipment.status,
      // collectionArrivalTime: shipment.journey.cat,
      // collectionDepartureTime: shipment.journey.cdt,
      // deliveryArrivalTime: shipment.journey.dat,
      // deliveryCompletionTime: shipment.journey.dct,
      

    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (success) {
      setValue('file', null);
    }
    return () => dispatch(shipmentsCleanUp());
  }, [dispatch, success, setValue]);

  const goBackMessage = useFormatMessage('ShipmentForm.goBack');

  // const pickAnotherFileMessage = useFormatMessage('ShipmentForm.pickAnotherFile');
  // const pickFileMessage = useFormatMessage('ShipmentForm.pickFile');

  // const calculateTimeSince = (date) => {
  //   const now = new Date()
  //   const collection = date
  //   const difference = now - collection;
  //   let timeSince = {};

  //   if (difference > 0) {
  //     timeSince = {
  //       hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
  //       minutes: Math.floor((difference / 1000 / 60) % 60),
  //       seconds: Math.floor((difference / 1000) % 60),
  //     };
  //   }

  //   return timeSince;
  // };

  // const [timeSince, setTimeSince] = useState(calculateTimeSince());

  // useEffect(() => {
  //   setTimeout(() => {
  //     setTimeSince(calculateTimeSince());
  //   }, 1000);
  // });

  // const timerComponents = [];
  // Object.keys(timeSince).forEach((interval) => {
  //   if (!timeSince[interval]) {
  //     return;
  //   }

  //   timerComponents.push(
  //     <span>
  //       {timeSince[interval]} {interval}{',\xa0'}
  //     </span>
  //   );
  // });

  const [timeSince, setTimeSince] = useState(null);

  const columns = [
    {
      Header: useFormatMessage('Item.Quantity'),
      accessor: 'amount',
    },
    {
      Header: useFormatMessage('Item.Name'),
      accessor: 'name',
    },
    {
      Header: useFormatMessage('Item.Description'),
      accessor: 'description',
    },
    {
      Header: useFormatMessage('Item.Length'),
      accessor: 'length',
    },
    {
      Header: useFormatMessage('Item.Width'),
      accessor: 'width',
    },
    {
      Header: useFormatMessage('Item.Height'),
      accessor: 'height',
    },
    {
      Header: useFormatMessage('Item.Weight'),
      accessor: 'weight',
    },
    {
      Header: useFormatMessage('Item.Value'),
      accessor: 'value',
    },
  ];

  const data = shipmentData.cargo.items;
  console.log(`${Cat}`,`${Cdt}`,`${Dat}`,`${Dct}`,statusD)

  const newShipment ={
    
    Cat: Cat,
    Cdt: Cdt,
    Dat: Dat,
    Dct: Dct,
    statusD: statusD,
    id: shipment.id,
  }

  const handleSubmit1 = () => {
    dispatch(modifyShipmentEnroute(newShipment))
  }
  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-dolly default" />
                </span>
                {useFormatMessage('EnrouteForm.Times')}
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                

                {/* <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ProcessForm.RemainingTime')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {timerComponents.length ? timerComponents : <span>Time's up!</span>}
                        </label>
                      </div>
                    </div>
                  </div>
                </div> */}

                
                <p className="card-header-title">
                  {useFormatMessage('EnrouteForm.Collection')}
                </p>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('EnrouteForm.ArrivalTime')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                   
                          <DatePicker
                          showTimeSelectOnly
                          selected={cat}
                          onChange={(date) => setCat(date)}
                          timeInputLabel="Time:"
                          dateFormat="hh:mm"
                          showTimeInput
                          showDisabledMonthNavigation
                     
                      />
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('EnrouteForm.GOBTime')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      
                          <DatePicker
                          showTimeSelectOnly
                          selected={cdt}
                          onChange={(date) => setCdt(date)}
                          timeInputLabel="Time:"
                          dateFormat="HH:mm"
                          showTimeInput
                          showDisabledMonthNavigation
                       
                      />
                    </div>
                  </div>
                </div>

                <hr />
                <p className="card-header-title">
                  {useFormatMessage('EnrouteForm.Delivery')}
                </p>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('EnrouteForm.ArrivalTime')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      
                          <DatePicker
                          showTimeSelectOnly
                          selected={dat}
                          onChange={(date) => setDat(date)}
                          timeInputLabel="Time:"
                          dateFormat="HH:mm"
                          showTimeInput
                          showDisabledMonthNavigation
                          
                      />
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('EnrouteForm.DepartureTime')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      
                          <DatePicker
                          showTimeSelectOnly
                          selected={dct}
                          onChange={(date) => setDct(date)}
                          timeInputLabel="Time:"
                          dateFormat="HH:mm"
                          showTimeInput
                          showDisabledMonthNavigation
                          />
                        
              
                    </div>
                  </div>
                </div>
                
                

                <hr />

                <div className="field has-check is-horizontal">
                  <div className="field-label">
                    <label className="label">{useFormatMessage('EnroutesForm.Mark as Delivered')}</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="field">
                        <div className="control">
                          <label className="b-checkbox checkbox">
                            <input
                              type="checkbox"
                              onChange={() => statusD? setStatusD(false): setStatusD(true)}
                            />
                            <span className="check is-primary" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                
                <hr />
                <div className="field is-horizontal">
                  <div className="field-label" />
                  
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                            onClick={() => handleSubmit1()}
                          >
                            <span>{useFormatMessage('ShipmentForm.submit')}</span>
                          </button>
                        </div>
                        {!isProfile && (
                          <Link to={paths.PROCESS_SHIPMENTS} className="button">
                            {goBackMessage}
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="tile is-parent preview">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-map-marker-path default" />
                </span>
                {useFormatMessage('EnrouteForm.ShipmentDetails')}
              </p>
            </header>
            <div className="card-content">
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">
                    {useFormatMessage('ShipmentForm.shipmentId')}
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <label className="input is-static">
                        {shipmentData.uid}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ShipmentForm.shipmentStatus')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.status}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ShipmentForm.shipmentDistance')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <label className="input is-static">
                            {shipmentData.distance}
                          </label>                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ShipmentForm.shipmentDuration')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.duration}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {shipmentData.urgent === true 
                ? <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        {useFormatMessage('QuoteForm.shipmentUrgent')}
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <label className="input is-static">
                            true
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                :<></>
                }
                {shipmentData.dedicated === true 
                ? <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        {useFormatMessage('QuoteForm.shipmentDedicated')}
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <label className="input is-static">
                            true
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                :<></>
                }
              <hr />
              
              <p className="card-header-title">
                  {useFormatMessage('QuoteForm.shipmentCargo')}
              </p>

              {shipmentData.cargo.type === "standard"?
              <>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('QuoteForm.cargoDescription')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.cargo.description}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('QuoteForm.cargoTotalWeight')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.cargo.totalWeight}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('QuoteForm.cargoLongestLength')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.cargo.longestLength}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('QuoteForm.cargoValue')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.cargo.value}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              :
              <>
              </>
              } 
              {shipmentData.cargo.type === "moreSpecific"?
              <>
                <div className="b-table">
                  <Table columns={columns} data={data} />
                </div>
              </>
              :
              <>
              </>
              }        
              {shipmentData.cargo.type === "vehicle"?
              <>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('QuoteForm.cargoDescription')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.cargo.description}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('QuoteForm.cargoSelectedVehicle')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.cargo.vehicle}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              :
              <>
              </>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

EnrouteForm.propTypes = {
  shipment: PropTypes.shape({
  }).isRequired,

  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object.isRequired,
  isProfile: PropTypes.bool,
};

EnrouteForm.defaultProps = {
  isProfile: false,
};

export default EnrouteForm;
