import { createReducer } from 'redux-act';

import {
  USERS_FETCH_SECRET_DATA_INIT,
  USERS_FETCH_SECRET_DATA_SUCCESS,
  USERS_FETCH_SECRET_DATA_FAIL,
  USERS_CLEAR_SECRET_DATA_LOGOUT,
  USERS_MODIFY_SECRET_DATA_SUCCESS,
} from 'state/actions/users';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const usersSecretReducer = createReducer(
  {
    [USERS_FETCH_SECRET_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [USERS_FETCH_SECRET_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.data,
      loading: false,
      error: null,
    }),
    [USERS_FETCH_SECRET_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [USERS_MODIFY_SECRET_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: !state.data
        ? []
        : state.data.map((elem) => {
            if (elem.id === payload.id) {
              return {
                isAdmin: payload.user.isAdmin,
                isBusiness: payload.user.isBusiness,
                id: payload.id,
                creditLimit: payload.user.creditLimit,
                drawnOnCredit: payload.user.drawnOnCredit,
              };
            }
            return elem;
          }),
      loading: false,
      error: null,
      success: true,
    }),
    [USERS_CLEAR_SECRET_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
