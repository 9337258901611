/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';

import paths from 'pages/Router/paths';
import { shipmentsCleanUp } from 'state/actions/shipments';
import { useFormatMessage } from 'hooks';

import Table from '../Table';
import './ShipmentForm.scss';


const ShipmentForm = ({ isProfile, shipment, onSubmitHandler, schema }) => {
  const { loading, success } = useSelector(
    (state) => ({
      loading: state.shipments.loading,
      success: state.shipments.success,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const shipmentData = {
    owner: shipment.creatorName,
    cargo: shipment.cargoDetails,
  };
  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: { 
      id: shipment.id,
      uid: shipment.uid,
      status: shipment.status,
      creation: shipment.creation,
      creator: shipment.creator,

      routeDistance: shipment.route.distance,
      routeDuration: shipment.route.duration,

      pickupContactName: shipment.shipmentDetails.collectionDetails.contactPerson,
      pickupContactPhone: shipment.shipmentDetails.collectionDetails.contactNumber,
      pickupBuildingNo: shipment.shipmentDetails.collectionDetails.address.buildingNo,
      pickupStreet: shipment.shipmentDetails.collectionDetails.address.street,
      pickupAddressDescription: shipment.shipmentDetails.collectionDetails.address.description,
      pickupPostcode: shipment.shipmentDetails.collectionDetails.address.postcode,

      deliveryContactName: shipment.shipmentDetails.deliveryDetails.contactPerson,
      deliveryContactPhone: shipment.shipmentDetails.deliveryDetails.contactNumber,
      deliveryBuildingNo: shipment.shipmentDetails.deliveryDetails.address.buildingNo,
      deliveryStreet: shipment.shipmentDetails.deliveryDetails.address.street,
      deliveryAddressDescription: shipment.shipmentDetails.deliveryDetails.address.description,
      deliveryPostcode: shipment.shipmentDetails.deliveryDetails.address.postcode,

      startDate: shipment.shipmentDetails.dateAndTime.date,
      collectionHour: shipment.shipmentDetails.dateAndTime.timeH,
      collectionMinute: shipment.shipmentDetails.dateAndTime.timeM,

      urgent: shipment.shipmentDetails.shipmentOptions.urgentCollection,
      dedicated: shipment.shipmentDetails.shipmentOptions.dedicatedVehicle,

      paymentStatus: shipment.paymentInformation?.paymentStatus,
      paymentMethod: shipment.paymentInformation?.paymentMethod? shipment.paymentInformation?.paymentMethod : "Not paid yet",

      quotePrice: shipment.quoteInformation? `£${(shipment.quoteInformation?.price/100).toFixed(2)} `: "",
      quoteTax: shipment.quoteInformation? `£${(shipment.quoteInformation?.tax/100).toFixed(2)} `: "",
      quoteTotal: shipment.quoteInformation? `£${((shipment.quoteInformation?.tax+shipment.quoteInformation?.price)/100).toFixed(2)} `: "",
      quoteCurrency: shipment.quoteInformation?.currency,
      quoteExpiryDate: shipment.quoteInformation? `${new Date(parseInt(shipment.quoteInformation.expiryDate, 10)).toLocaleString("en-GB",{timeZone: "Europe/London"})} UK time`:"No quote issued",
      quoteIssuanceDate: shipment.quoteInformation? `${new Date(parseInt(shipment.quoteInformation.issueDate, 10)).toLocaleString("en-GB",{timeZone: "Europe/London"})} UK time`:"No quote issued",
      

      cat: shipment.routeTime.cat,
      cdt: shipment.routeTime.cdt,
      dat: shipment.routeTime.dat,
      dct: shipment.routeTime.dct,
    },
    resolver: yupResolver(schema),
  });
  
  useEffect(() => {
    if (success) {
      setValue('file', null);
    }
    return () => dispatch(shipmentsCleanUp());
  }, [dispatch, success, setValue]);

  const goBackMessage = useFormatMessage('ShipmentForm.goBack');

  // const pickAnotherFileMessage = useFormatMessage('ShipmentForm.pickAnotherFile');
  // const pickFileMessage = useFormatMessage('ShipmentForm.pickFile');

  const CollectingPersonMessage = useFormatMessage('ShipmentForm.CollectingPerson');

  const CollectingPhoneMessage = useFormatMessage('ShipmentForm.CollectingPhone');

  const CollectingPremiseMessage = useFormatMessage('ShipmentForm.CollectingPremise');
  const CollectingStreetMessage = useFormatMessage('ShipmentForm.CollectingStreet');
  const CollectingDescriptionMessage = useFormatMessage('ShipmentForm.CollectingDescription');
  const CollectingPostcodeMessage = useFormatMessage('ShipmentForm.CollectingPostcode');


  const DedicatedVehicleMessage = useFormatMessage('ShipmentForm.dedicatedVehicle');
  const UrgentMessage = useFormatMessage('ShipmentForm.urgent');


  const columns = [
    {
      Header: useFormatMessage('Item.Quantity'),
      accessor: 'amount',
    },
    {
      Header: useFormatMessage('Item.Name'),
      accessor: 'name',
    },
    {
      Header: useFormatMessage('Item.Description'),
      accessor: 'description',
    },
    {
      Header: useFormatMessage('Item.Length'),
      accessor: 'length',
    },
    {
      Header: useFormatMessage('Item.Width'),
      accessor: 'width',
    },
    {
      Header: useFormatMessage('Item.Height'),
      accessor: 'height',
    },
    {
      Header: useFormatMessage('Item.Weight'),
      accessor: 'weight',
    },
    {
      Header: useFormatMessage('Item.Value'),
      accessor: 'value',
    },
  ];

  const data = shipmentData.cargo.items;

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-package-variant default" />
                </span>
                {useFormatMessage('ShipmentForm.shipmentInfo')}
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ShipmentForm.shipmentId')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          type="text"
                          readOnly="readOnly"
                          className="input is-static"
                          name="uid"
                          ref={register}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ShipmentForm.userName')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.owner}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ShipmentForm.shipmentStatus')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          type="text"
                          className="input"
                          name="status"
                          ref={register}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ShipmentForm.shipmentDistance')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          type="text"
                          readOnly="readOnly"
                          className="input is-static"
                          name= "routeDistance"
                          ref={register}
                        />
                        <label className="label">
                          miles
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ShipmentForm.shipmentDuration')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          type="text"
                          readOnly="readOnly"
                          className="input is-static"
                          name= "routeDuration"
                          ref={register}
                        />
                      </div>
                    </div>
                  </div>
                </div>

              <hr />

              <p className="card-header-title">
                {useFormatMessage('ShipmentForm.collectionDate')}
              </p>

              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">
                    {useFormatMessage('ShipmentForm.date')}
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                    <input
                        type="text"
                        className="input"
                        name="startDate"
                        ref={register}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">
                    {useFormatMessage('ShipmentForm.hour')}
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                    <input
                        type="text"
                        className="input"
                        name="collectionHour"
                        ref={register}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">
                    {useFormatMessage('ShipmentForm.minute')}
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                    <input
                        type="text"
                        className="input"
                        name="collectionMinute"
                        ref={register}
                      />
                    </div>
                  </div>
                </div>
              </div>

            
              <div className="field has-check is-horizontal">
                <div className="field-label">
                  <label className="label">{UrgentMessage}</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="field">
                      <div className="control">
                        <label className="b-checkbox checkbox">
                          <input
                            type="checkbox"
                            name="urgent"
                            ref={register}
                          />
                          <span className="check is-primary" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="field has-check is-horizontal">
                <div className="field-label">
                  <label className="label">{DedicatedVehicleMessage}</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="field">
                      <div className="control">
                        <label className="b-checkbox checkbox">
                          <input
                            type="checkbox"
                            name="dedicated"
                            ref={register}
                          />
                          <span className="check is-primary" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

                <p className="card-header-title">
                  {useFormatMessage('ShipmentForm.CollectionAddress')}
                </p>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {CollectingPersonMessage}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          type="text"
                          className="input"
                          name="pickupContactName"
                          ref={register}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {CollectingPhoneMessage}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          type="text"
                          className="input"
                          name="pickupContactPhone"
                          ref={register}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {CollectingPremiseMessage}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          type="text"
                          className="input"
                          name="pickupBuildingNo"
                          ref={register}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                
                

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {CollectingStreetMessage}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                      <input
                          type="text"
                          className="input"
                          name="pickupStreet"
                          ref={register}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {CollectingDescriptionMessage}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                      <input
                          type="text"
                          className="input"
                          name="pickupAddressDescription"
                          ref={register}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {CollectingPostcodeMessage}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                      <input
                          type="text"
                          className="input"
                          name="pickupPostcode"
                          ref={register}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <p className="card-header-title">
                  {useFormatMessage('ShipmentForm.DeliveryAddress')}
                </p>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {CollectingPersonMessage}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          type="text"
                          className="input"
                          name="deliveryContactName"
                          ref={register}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {CollectingPhoneMessage}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          type="text"
                          className="input"
                          name="deliveryContactPhone"
                          ref={register}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {CollectingPremiseMessage}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          type="text"
                          className="input"
                          name="deliveryBuildingNo"
                          ref={register}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                
                

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {CollectingStreetMessage}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                      <input
                          type="text"
                          className="input"
                          name="deliveryStreet"
                          ref={register}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {CollectingDescriptionMessage}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                      <input
                          type="text"
                          className="input"
                          name="deliveryAddressDescription"
                          ref={register}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {CollectingPostcodeMessage}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                      <input
                          type="text"
                          className="input"
                          name="deliveryPostcode"
                          ref={register}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <p className="card-header-title">
                  {useFormatMessage('Meta Data')}
                </p>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('Database reference ID')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                      <input
                          type="text"
                          className="input"
                          name="id"
                          ref={register}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('Owner')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                      <input
                          type="text"
                          className="input"
                          name="creator"
                          ref={register}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('Creation Date')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                      <input
                          type="text"
                          className="input"
                          name="creation"
                          ref={register}
                        />
                      </div>
                    </div>
                  </div>
                </div>


                
                
                


                

                <hr />

                {/* <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ShipmentForm.logo')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="file has-name">
                        <label className="file-label">
                          <input
                            className="file-input"
                            type="file"
                            name="file"
                            ref={register}
                            accept="image/*"
                          />
                          <span className="file-cta">
                            <span className="file-icon">
                              <i className="mdi mdi-upload" />
                            </span>
                            <span className="file-label">
                              {watch('file') && watch('file').file
                                ? pickAnotherFileMessage
                                : pickFileMessage}
                            </span>
                          </span>
                          <span className="file-name">
                            {watch('file') && watch('file')[0]?.name}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div> */}

                <hr />
                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="submit"
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                          >
                            <span>{useFormatMessage('ShipmentForm.submit')}</span>
                          </button>
                        </div>
                        {!isProfile && (
                          <Link to={paths.SHIPMENTS} className="button">
                            {goBackMessage}
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="tile is-parent preview">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account default" />
                </span>
                {useFormatMessage('ShipmentForm.shipmentPreview')}
              </p>
            </header>
            <div className="card-content">
              
            <p className="card-header-title">
                  {useFormatMessage('QuoteForm.shipmentCargo')}
              </p>

              {shipmentData.cargo.type === "standard"?
              <>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('QuoteForm.cargoDescription')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.cargo.description}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('QuoteForm.cargoTotalWeight')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.cargo.totalWeight}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('QuoteForm.cargoLongestLength')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.cargo.longestLength}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('QuoteForm.cargoValue')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.cargo.value}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              :
              <>
              </>
              } 
              {shipmentData.cargo.type === "moreSpecific"?
              <>
                <div className="b-table">
                  <Table columns={columns} data={data} />
                </div>
              </>
              :
              <>
              </>
              }        
              {shipmentData.cargo.type === "vehicle"?
              <>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('QuoteForm.cargoDescription')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.cargo.description}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('QuoteForm.cargoSelectedVehicle')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.cargo.vehicle}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              :
              <>
              </>
              }
              
              <hr />
              <p className="card-header-title">
                  {useFormatMessage('ShipmentForm.QuoteInfromation')}
              </p>

              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">
                    {useFormatMessage('ShipmentForm.QuotePrice')}
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <div className="control is-clearfix">
                        <input
                          data-testid="name"
                          type="text"
                          readOnly="readOnly"
                          className="input is-static"
                          value={watch('quotePrice')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">
                    {useFormatMessage('ShipmentForm.QuoteTax')}
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <div className="control is-clearfix">
                        <input
                          data-testid="name"
                          type="text"
                          readOnly="readOnly"
                          className="input is-static"
                          value={watch('quoteTax')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">
                    {useFormatMessage('ShipmentForm.QuoteTotal')}
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <div className="control is-clearfix">
                        <input
                          data-testid="name"
                          type="text"
                          readOnly="readOnly"
                          className="input is-static"
                          value={watch('quoteTotal')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">
                    {useFormatMessage('ShipmentForm.QuoteIssuanceDate')}
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <div className="control is-clearfix">
                        <input
                          data-testid="name"
                          type="text"
                          readOnly="readOnly"
                          className="input is-static"
                          value={watch('quoteIssuanceDate')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">
                    {useFormatMessage('ShipmentForm.QuoteExpiryDate')}
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <div className="control is-clearfix">
                        <input
                          data-testid="name"
                          type="text"
                          readOnly="readOnly"
                          className="input is-static"
                          value={watch('quoteExpiryDate')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">
                    {useFormatMessage('ShipmentForm.PaymentStatus')}
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <div className="control is-clearfix">
                        <input
                          data-testid="name"
                          type="text"
                          readOnly="readOnly"
                          className="input is-static"
                          value={watch('paymentStatus')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">
                    {useFormatMessage('ShipmentForm.PaymentMethod')}
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <div className="control is-clearfix">
                        <input
                          data-testid="name"
                          type="text"
                          readOnly="readOnly"
                          className="input is-static"
                          value={watch('paymentMethod')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            
      

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ShipmentForm.propTypes = {
  shipment: PropTypes.shape({
    status: PropTypes.string.isRequired,
    creation: PropTypes.string,
    creator: PropTypes.string,
    routeDistance: PropTypes.string,
    routeDuration: PropTypes.string,
    pickupContactName: PropTypes.string,
    pickupContactPhone: PropTypes.string,
    pickupBuildingNo: PropTypes.string,
    pickupStreet: PropTypes.string,
    pickupAddressDescription: PropTypes.string,
    pickupPostcode: PropTypes.string,
    deliveryContactName: PropTypes.string,
    deliveryContactPhone: PropTypes.string,
    deliveryBuildingNo: PropTypes.string,
    deliveryStreet: PropTypes.string,
    deliveryAddressDescription: PropTypes.string,
    deliveryPostcode: PropTypes.string,
    startDate: PropTypes.string,
    collectionHour: PropTypes.string,
    collectionMinute: PropTypes.string,
    urgent: PropTypes.bool,
    dedicated: PropTypes.bool,
    cat: PropTypes.string,
    cdt: PropTypes.string,
    dat: PropTypes.string,
    dct: PropTypes.string,
  }).isRequired,

  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object.isRequired,
  isProfile: PropTypes.bool,
};

ShipmentForm.defaultProps = {
  isProfile: false,
};

export default ShipmentForm;
