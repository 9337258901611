import React, { useEffect, useMemo } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as yup from 'yup';

import DeliveredForm from 'components/DeliveredForm';
import { fetchShipments } from 'state/actions/shipments';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';


const schema = yup.object().shape({
});

const Delivered = () => {
  const { id } = useParams();

  const isEditing = useMemo(() => !!id, [id]);

  const { success, shipmentData, error } = useSelector(
    (state) => ({
      success: state.shipments.success,
      shipmentData: state.shipments.data.find((shipment) => shipment.id === id),
      error: state.shipments.error,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing) {
      if (!shipmentData) {
        dispatch(fetchShipments(id));
      }
    }
  }, [isEditing, id, shipmentData, dispatch]);

  const redirect = ((isEditing && error) || success) && (
    <Redirect to={paths.DELIVERED} />
  );

  const editShipmentMessage = useFormatMessage('Shipment.editShipment');

  const newShipmentMessage = useFormatMessage('Shipment.newShipment');

  const onSubmitHandler = () => {
    // console.log('hello there');
    // const newShipment = {
    //   ...value,
    //   file: value?.file[0] || null,
    //   isEditing,
    //   id,
    // };
    // newShipment;

    // if (isEditing) {
    //   dispatch(modifyShipmentDelivery(newShipment));
    // } else {
    //   dispatch(createShipment(newShipment));
    // }
  };

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editShipmentMessage : newShipmentMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !shipmentData ? (
          <ClipLoader />
        ) : (
          <DeliveredForm
            isEditing={isEditing}
            shipment={
              isEditing
                ? shipmentData
                : {
                    name: '',
                    email: '',
                    location: '',
                    isAdmin: false,
                  }
            }
            onSubmitHandler={onSubmitHandler}
            schema={schema}
          />
        )}
      </section>
    </>
  );
};

export default Delivered;
