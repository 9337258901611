/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';

import paths from 'pages/Router/paths';
import { shipmentsCleanUp } from 'state/actions/shipments';
import { createQuotePrice } from 'state/actions/quotes';
import { useFormatMessage } from 'hooks';
import {useJsApiLoader, GoogleMap, DirectionsRenderer} from '@react-google-maps/api';
// import ErrorMessage from 'components/ErrorMessage';
import Table from '../Table';
import './QuoteForm.scss';

/* eslint-disable */
const QuoteForm = ({ isProfile, shipment, onSubmitHandler, schema }) => {
  const {isLoaded} = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  })
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const distance = shipment.type == "standard" ? shipment.route.distance : (shipment.route.distance/ 1609.344).toFixed(2)
  async function calculateRoute() {
    const directionsService = new google.maps.DirectionsService();
    if (!shipment.type || shipment.type === "standard") {
      // Use the old system
      const result = await directionsService.route({
        origin: shipment.shipmentDetails.collectionDetails.address.postcode,
        destination: shipment.shipmentDetails.deliveryDetails.address.postcode,
        travelMode: google.maps.TravelMode.DRIVING
      });
      setDirectionsResponse(result);
    } else {
      // Use the new system with waypoints
      const origin = shipment.shipmentDetails.addresses[0].postCode;
      const destination = shipment.shipmentDetails.addresses.slice(-1)[0].postCode;
      const waypoints = shipment.shipmentDetails.addresses
        .slice(1, -1)
        .map(address => ({ location: address.postCode }));
  
      const result = await directionsService.route({
        origin: origin, 
        destination: destination,
        waypoints: waypoints,
        optimizeWaypoints: false, // Optimize the order of waypoints for efficiency
        travelMode: google.maps.TravelMode.DRIVING
      });
      setDirectionsResponse(result);
    }
  }


  useEffect(() => {
    if (isLoaded) {
      calculateRoute();
    }
  }, [isLoaded]);
  

  const { loading, success } = useSelector(
    (state) => ({
      loading: state.shipments.loading,
      success: state.shipments.success,
    }),
    shallowEqual
  );
  const { loadingQ } = useSelector(
    (state) => ({
      loadingQ: state.quotes.loading,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const [selectedVehicle, setSelectedVehicle] = useState("default");
  const [calcPrice, setCalcPrice] = useState("None");
  const [pM, setPM] = useState(0);
  const [minP, setMinP] = useState(0);
  const [stepPrice2, setStepPrice2] = useState(0);
  const [dedicatedCharge, setDedicatedCharge] = useState(0);
  const [urgentCharge, setUrgentCharge] = useState(0);
  const [tollCharge, setTollCharge] = useState(0);
  const [congestionCharge, setCongestionCharge] = useState(0);

  const [selectToll, setSelectToll] = useState(false);
  const [selectCZ, setSelectCZ] = useState(false);

  console.log('Dartmouth Toll applied:', selectToll)
  console.log('Congestion Zone applied:', selectCZ)
  const handleCalcPrice = async() =>{
  // let newCalcPrice = 0;
    const data ={
      seVehicle: selectedVehicle,
      distance: distance,
      duration: shipment.route.duration,
      dedicated: shipment.shipmentDetails.shipmentOptions.dedicatedVehicle,
      urgent: shipment.shipmentDetails.shipmentOptions.urgentCollection,
      tollDartford: selectToll,
      congestionChargeLdn: selectCZ,
    };
    let price = null
    price = await dispatch(createQuotePrice(data));

    if(price != null){
      console.log(price)
      setCalcPrice(price.data.price.toFixed(2));
      setPM(price.data.pM.toFixed(2));
      setMinP(price.data.minP.toFixed(2));
      setStepPrice2(price.data.stepPrice2.toFixed(2));
      setDedicatedCharge(price.data.dedicatedCharge.toFixed(2));
      setUrgentCharge(price.data.urgentCharge.toFixed(2));
      setTollCharge(price.data.tollCharge.toFixed(2));
      setCongestionCharge(price.data.congestionCharge.toFixed(2));
      
    }
    
  };

  
  
  const shipmentData = {
    id: shipment.uid,
    owner: shipment.creatorName,
    status: shipment.status,
    distance: distance,
    duration: shipment.route.duration,
    cargo: shipment.cargoDetails,
    dedicated: shipment.shipmentDetails.shipmentOptions.dedicatedVehicle,
    urgent: shipment.shipmentDetails.shipmentOptions.urgentCollection,
  };
  
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {  
    selectedVehicle: selectedVehicle,
    },
    resolver: yupResolver(schema),
  });
  

  useEffect(() => {
    if (success) {
      setValue('file', null);
    }
    return () => dispatch(shipmentsCleanUp());
  }, [dispatch, success, setValue]);

  const goBackMessage = useFormatMessage('ShipmentForm.goBack');

  // const pickAnotherFileMessage = useFormatMessage('ShipmentForm.pickAnotherFile');
  // const pickFileMessage = useFormatMessage('ShipmentForm.pickFile');

  const columns = [
    {
      Header: useFormatMessage('Item.Quantity'),
      accessor: 'amount',
    },
    {
      Header: useFormatMessage('Item.Name'),
      accessor: 'name',
    },
    {
      Header: useFormatMessage('Item.Description'),
      accessor: 'description',
    },
    {
      Header: useFormatMessage('Item.Length'),
      accessor: 'length',
    },
    {
      Header: useFormatMessage('Item.Width'),
      accessor: 'width',
    },
    {
      Header: useFormatMessage('Item.Height'),
      accessor: 'height',
    },
    {
      Header: useFormatMessage('Item.Weight'),
      accessor: 'weight',
    },
    {
      Header: useFormatMessage('Item.Value'),
      accessor: 'value',
    },
  ];

  const data = shipmentData.cargo.items;

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-pen-plus default" />
                </span>
                {useFormatMessage('QuoteForm.addQuote')}
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ShipmentForm.shipmentId')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.id}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ShipmentForm.userName')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.owner}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ShipmentForm.shipmentStatus')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.status}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ShipmentForm.shipmentDistance')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <label className="input is-static">
                            {shipmentData.distance}
                          </label>                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ShipmentForm.shipmentDuration')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.duration}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {shipmentData.urgent === true 
                ? <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">
                    {useFormatMessage('QuoteForm.shipmentUrgent')}
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <label className="input is-static">
                        true
                      </label>
                    </div>
                  </div>
                </div>
              </div>
                :
                <></>
                }
                {shipmentData.dedicated === true 
                ? <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">
                    {useFormatMessage('QuoteForm.shipmentDedicated')}
                  </label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <label className="input is-static">
                        true
                      </label>
                    </div>
                  </div>
                </div>
              </div>
                :
                <></>
                }
              <hr />
              
              <p className="card-header-title">
                  {useFormatMessage('QuoteForm.shipmentCargo')}
              </p>

              {shipmentData.cargo.type === "standard"?
              <>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('QuoteForm.cargoDescription')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.cargo.description}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('QuoteForm.cargoTotalWeight')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.cargo.totalWeight}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('QuoteForm.cargoLongestLength')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.cargo.longestLength}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('QuoteForm.cargoValue')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.cargo.value}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              :
              <>
              </>
              } 
              {shipmentData.cargo.type === "moreSpecific"?
              <>
                <div className="b-table">
                  <Table columns={columns} data={data} />
                </div>
              </>
              :
              <>
              </>
              }        
              {shipmentData.cargo.type === "vehicle"?
              <>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('QuoteForm.cargoDescription')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.cargo.description}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('QuoteForm.cargoSelectedVehicle')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="input is-static">
                          {shipmentData.cargo.vehicle}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              :
              <>
              </>
              }
              <hr />

              
                <div className="field has-check is-horizontal">
                  <div className="field-label">
                    <label className="label">Dartford Toll</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="field">
                        <div className="control">
                          <label className="b-checkbox checkbox">
                            <input
                              type="checkbox"
                              onChange={() => selectToll? setSelectToll(false): setSelectToll(true)}
                            />
                            <span className="check is-primary" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field has-check is-horizontal">
                  <div className="field-label">
                    <label className="label">Congestion Zone</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="field">
                        <div className="control">
                          <label className="b-checkbox checkbox">
                            <input
                              type="checkbox"
                              onChange={() => selectCZ? setSelectCZ(false): setSelectCZ(true)}
                            />
                            <span className="check is-primary" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="field is-horizontal">
                  <div className="field-label" />
                    <div className="field-body">
                      <div className="field">
                        <div className="field is-grouped">
                          <div className="control">
                            <select 
                              onChange={(val) => {setValue('select', val.target.value,{ shouldValidate: true }), setSelectedVehicle(val.target.value)}} 
                              className="input" 
                              name="selectedVehicle"
                              ref={register}>
                              <option value="default" disabled hidden>Select Vehicle</option>
                              <option value="sv" >Small Van</option>
                              <option value="swb" >Short Wheel Base Van</option>
                              <option value="mwb">Medium Wheel Base Van</option>
                              <option value="lwb">Long Wheel Base Van</option>
                              <option value="xlwb">Extra Long Wheel Base Van</option>
                              <option value="bv">Box Van / Luton</option>
                            </select>
                          </div>
                          <div className="control">
                            <button
                              type="button"
                              onClick={() => handleCalcPrice(selectedVehicle)}
                              className={`button is-primary ${
                                loadingQ && 'is-loading'
                              }`}
                            >
                            <span>{useFormatMessage('QuoteForm.getCalcPrice')}</span>
                            <span style={{width: 10}}> </span>
                            <i className="mdi mdi-autorenew" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <hr />

                <p className="card-header-title">
                  {useFormatMessage('QuoteForm.CalculatedPrice')}
                </p>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      £{calcPrice}
                    </label>
                  </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="field is-grouped">
                          <div className="control">
                          <input
                            type="text"
                            className="input"
                            name="newPrice"
                            placeholder="Enter Final Price"
                            ref={register}
                          />
                          </div>
                          <div className="control">
                          <button
                            type="submit"
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                          >
                            <span>{useFormatMessage('ShipmentForm.submit')}</span>
                          </button>
                          </div>
                            <div className="control">
                              {!isProfile && (
                              <Link to={paths.QUOTES} className="button">
                                {goBackMessage}
                              </Link>
                            )}
                          </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
                
                
              </form>
            </div>
          </div>
        </div>
        <div className="tile is-parent preview">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-map-marker-path default" />
                </span>
                {useFormatMessage('QuoteForm.RoutePreview')}
              </p>
            </header>
            <div style={{display: "flex", height: "100%", flexDirection: "column", justifyContent: "space-between"}} className="card-content">
              <div>
                <div>
                  
                  {isLoaded ? (
                    <GoogleMap 
                  mapContainerStyle={{width: '100%', height: '300px'}}
                  center={{lat: 51.51526007507941,lng: -0.1277273911187215}}
                  zoom={15}
                  options={{
                    streetViewControl: false,
                    zoomControl: false,
                    mapTypeControl: false,
                  }}
                  >
                    {directionsResponse && <DirectionsRenderer directions={directionsResponse}/>}
                  </GoogleMap>
                  ) : <></>}
                </div>
                <button style={{marginTop: 5}} className={'button is-primary'} onClick={calculateRoute}>
                    Show Route
                </button>
                
              </div> 
              <div style={{paddingBottom: 50}}>
              <label className="label">
                Suggested Quote Breakdown
              </label> 
              {calcPrice !== "None"?
                <div>
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: `1px solid verylightgray`, paddingTop: 10, paddingBottom: 5, marginBottom: 5}}>
                    <text>£{pM} per mile x {shipmentData.distance} miles = £{(pM * shipmentData.distance).toFixed(2)} (min £{minP})</text>
                    <text style={{fontWeight: "bold"}}>£{stepPrice2}</text>
                  </div>
                  <div>

                    Other fees:
                  </div>
                  {urgentCharge != 0?
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <text>Urgent</text>
                    <text style={{fontWeight: "bold"}}>£{urgentCharge}</text>
                  </div>
                  :
                  <></>
                  }
                  {dedicatedCharge != 0?
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <text>Dedicated</text>
                    <text>£{dedicatedCharge}</text>
                  </div>
                  :
                  <></>
                  }
                  {congestionCharge != 0?
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <text>Congestion Zone Charge</text>
                    <text>£{congestionCharge}</text>
                  </div>
                  :
                  <></>
                  }
                  {tollCharge != 0?
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <text>Tolls</text>
                    <text>£{tollCharge}</text>
                  </div>
                  :
                  <></>
                  }
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 20, borderTop: `1px solid black`, paddingTop: 20, paddingBottom: 5}}>
                    <text>Total (ex. VAT)</text>
                    <text style={{fontWeight: "bold"}}>£{calcPrice}</text>
                  </div>
                </div>
              :
              <div>
                Price breakdown is visible after calculation
              </div>
              }
              </div> 
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

QuoteForm.propTypes = {
  shipment: PropTypes.shape({
    newPrice: PropTypes.string.isRequired,
    selectedVehicle: PropTypes.string.isRequired,
  }).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object.isRequired,
  isProfile: PropTypes.bool,
};

QuoteForm.defaultProps = {
  isProfile: false,
};

export default QuoteForm;
