import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reducer as toastrReducer } from 'react-redux-toastr';

import { chatsReducer } from './chat';
import { authReducer } from './auth';
import { usersReducer } from './users';
import { preferencesReducer } from './preferences';
import { shipmentsReducer } from './shipments';
import { quotesReducer } from './quotes';
import { processesReducer } from './processes';
import { usersSecretReducer } from './usersSecretData';


export default combineReducers({
  auth: persistReducer(
    {
      key: 'auth',
      storage,
      blacklist: ['error', 'loading'],
    },
    authReducer
  ),
  preferences: persistReducer(
    { key: 'preferences', storage },
    preferencesReducer
  ),
  users: persistReducer(
    {
      key: 'users',
      storage,
      blacklist: ['error', 'loading'],
    },
    usersReducer
  ),
  usersSecret: persistReducer(
    {
      key: 'usersSecret',
      storage,
      blacklist: ['error', 'loading'],
    },
    usersSecretReducer
  ),
  shipments: persistReducer(
    {
      key: 'shipments',
      storage,
      blacklist: ['error', 'loading'],
    },
    shipmentsReducer
  ),
  secretShipments: persistReducer(
    {
      key: 'processes',
      storage,
      blacklist: ['error', 'loading'],
    },
    processesReducer

  ),
  chats: persistReducer(
    {
      key: 'chats',
      storage,
      blacklist: ['error', 'loading'],
    },
    chatsReducer
  ),
  
  quotes: persistReducer(
    {
      key: 'quotes',
      storage,
      blacklist: ['error', 'loading'],
    },
    quotesReducer
  ),
  toastr: toastrReducer,
});
