import { createReducer } from 'redux-act';

import {
  SHIPMENTS_FETCH_SECRET_DATA_INIT,
  SHIPMENTS_FETCH_SECRET_DATA_SUCCESS,
  SHIPMENTS_FETCH_SECRET_DATA_FAIL,
  SHIPMENTS_CLEAR_SECRET_DATA_LOGOUT,
  SHIPMENTS_MODIFY_SECRET_DATA_SUCCESS,
} from 'state/actions/shipments';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const processesReducer = createReducer(
  {
    [SHIPMENTS_FETCH_SECRET_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [SHIPMENTS_FETCH_SECRET_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.data,
      loading: false,
      error: null,
    }),
    [SHIPMENTS_FETCH_SECRET_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SHIPMENTS_MODIFY_SECRET_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: !state.data
        ? []
        : state.data.map((elem) => {
            if (elem.id === payload.id) {
              return {
                handler: payload.shipment.handler,
                notes: payload.shipment.notes,
                id: payload.id,
                reference: payload.shipment.reference,
              };
            }
            return elem;
          }),
      loading: false,
      error: null,
      success: true,
    }),
    [SHIPMENTS_CLEAR_SECRET_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);

