import React from 'react';
import { useSelector } from 'react-redux';
import ChatListItem from './item';
import './styles.scss';
import ChatSingleScreen from './chatScreen';






export default function ChatListComponent() {
    const chats = useSelector(state => state.chats.data);
    
    const currentUserShipments = useSelector(state => state.shipments.data);
    const chatId = useSelector(state => state.chats.chatId);
    
    const chat = chats.filter((item) => item.id === chatId);
    
  

    console.log(chat[0]);
    console.log(chatId);
    // function Item(props) {
    //     const { value } = props;
    //     return <li>{value}</li>;
    //  }
     
     function MyList(items) {
        return (
         <div className='chatListContainer'>
           {items.data.map((item) => <ChatListItem key={item.key} chat={item} activeChat={chatId} />)}
         </div>
         
       );
     }
    return (
        <>
            {!currentUserShipments?
            <div />
            :
            <div className='container24'>
                <div style={{width: '30%'}}>
                    <div className='chatHeaderTitleWrapper'>
                        <div className='profileImage'/>
                        <header className='supportChatHeader'>Open cases</header>
                        <a className='supportLink'>View Closed Cases</a>
                    </div>
                    <div className='chatFilterWrapper'>
                        <i className="mdi mdi-magnify magnifyIcon" />
                        <form className='filterForm'>
                            
                            <input className='filterInput' 
                            placeholder='Search Chat' 
                            />
                        </form>
                    </div>
                    <MyList data={chats}/> 
                </div>

                {chatId?
                <div className='chatScreenContainer'>
                    <ChatSingleScreen chatId={chatId} data={chat[0]}/>
                </div>
                :
                <>
                </>
                }
            </div>
            }
        </>
    );
}