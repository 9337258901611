/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CHATID_SET } from 'state/actions/chats';
import './styles.scss';

const ChatListItem = ({ chat, activeChat }) => {
    
    const client = useSelector(state => state.users.data.filter((item) => item.id === chat.creator)[0]);
    const dispatch = useDispatch();
    const isActive = activeChat === chat.id;
    const currentUserShipments = useSelector(state => state.shipments.data);
    const chatId = chat.id;
    const handlePress = () => {
        dispatch(CHATID_SET({data: chatId}));
    };
    console.log(activeChat);
    // currentUserShipments.filter((shipment) => shipment.id == chat.shipmentId)[0]
    const shipmentData = chat.shipmentId === false ?
        false
        :
        currentUserShipments.filter((shipment) => shipment.id === chat.shipmentId)[0];     
    
  return (
    <button className='chatSelect' style={{backgroundColor: isActive? '#f1f2f5' : ''}} onClick={() => handlePress()}>
        <div className='profileImageWrapper'>
            <div className='profileImage'/>
        </div>
        <div className='textWrapper'>
            <div className='textLineWrapper'>
                <p className="supportTitle" >{chat.category}&nbsp;{'/'}&nbsp;</p>
                <p className="supportTopic" >{chat.subCategory}</p>
                <p className="shipmentNo" >{shipmentData?.uid}</p>
            </div>
            <div className='textLineWrapper'>
            <p className="nameLine">{client.isCompany? client.company.companyName : `${client.profile.userDetails.firstName} ${client.profile.userDetails.lastName}`}</p>
            </div>
            <div className='textLineWrapper3'>
                <p className="lastMessage" >{chat.lastMessage}</p>
                <p className="lastMessageTime" >{chat.lastUpdate? new Date(chat.lastUpdate.seconds * 1000).toLocaleTimeString('en-GB').slice(0,5) : 'Now'}</p>
            </div>
        </div>
    </button>
  );
};

export default ChatListItem;