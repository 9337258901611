
import { createAction } from "redux-act";

export const CHATS_SET = createAction('CHATS_SET');

export const CHATID_SET = createAction('CHATID_SET');



export const setChats = (chats) => {
    return async (dispatch) => {
    dispatch(CHATS_SET({data: chats}));
    };
};    

// export const setActiveChatId = (newChatId) => {
//     return async (dispatch) => {
//     dispatch(CHATID_SET({data: newChatId}));
//     };
// };  