export default {
  ROOT: '/',
  LOGIN: '/login',
  USERS: '/users',
  ADD_USER: '/users/new',
  MODIFY_USER: '/users/:id',
  SHIPMENTS: '/shipments',
  ADD_SHIPMENT: '/shipments/new',
  MODIFY_SHIPMENT: '/shipments/:id',
  PROFILE: '/profile',
  RESET_PASSWORD: '/recover-password',
  SECTION: '/section',
  QUOTES: '/quotes',
  MODIFY_QUOTE: '/quotes/:id',
  NEW_QUOTE: '/quotes/new',
  SUBMENU_2: '/submenu2',
  PROCESS_SHIPMENTS: '/processshipments',
  MODIFY_PROCESS: '/processshipment/:id',
  ENROUTES: '/enroute-shipments',
  MODIFY_ENROUTES: '/enroute-shipment/:id',
  DELIVERED: '/delivered-shipments',
  MODIFY_DELIVERED: '/delivered-shipments/:id',
  SUPPORT: '/support'
};
