import { createReducer } from 'redux-act';

import {
  SHIPMENTS_FETCH_DATA_INIT,
  SHIPMENTS_FETCH_DATA_SUCCESS,
  SHIPMENTS_FETCH_DATA_FAIL,
  SHIPMENTS_DELETE_SHIPMENT_INIT,
  SHIPMENTS_DELETE_SHIPMENT_SUCCESS,
  SHIPMENTS_DELETE_SHIPMENT_FAIL,
  SHIPMENTS_CREATE_SHIPMENT_INIT,
  SHIPMENTS_CREATE_SHIPMENT_SUCCESS,
  SHIPMENTS_CREATE_SHIPMENT_FAIL,
  SHIPMENTS_MODIFY_SHIPMENT_INIT,
  SHIPMENTS_MODIFY_SHIPMENT_SUCCESS,
  SHIPMENTS_MODIFY_SHIPMENT_FAIL,
  SHIPMENTS_CLEAN_UP,
  SHIPMENTS_CLEAR_DATA_LOGOUT,
} from 'state/actions/shipments';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};


export const shipmentsReducer = createReducer(
  {
    [SHIPMENTS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [SHIPMENTS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.data,
      loading: false,
      error: null,
    }),
    [SHIPMENTS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),

    [SHIPMENTS_DELETE_SHIPMENT_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [SHIPMENTS_DELETE_SHIPMENT_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [SHIPMENTS_DELETE_SHIPMENT_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SHIPMENTS_CREATE_SHIPMENT_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [SHIPMENTS_CREATE_SHIPMENT_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.concat(payload.shipment),
      loading: false,
      error: null,
      success: true,
    }),
    [SHIPMENTS_CREATE_SHIPMENT_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SHIPMENTS_MODIFY_SHIPMENT_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [SHIPMENTS_MODIFY_SHIPMENT_SUCCESS]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
    }),
    [SHIPMENTS_MODIFY_SHIPMENT_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SHIPMENTS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [SHIPMENTS_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
